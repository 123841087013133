import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  colors,
  Chip,
} from "@material-ui/core";
import { toast } from "react-toastify";
import axios from "axios";
import { APIURL } from "src/utils/misc";
import { async_fetch_event } from "src/actions/event.action";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { connect } from "react-redux";

function EventListResults({ events, async_fetch_event }) {
  const navigate = useNavigate();
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(0);
  const [copied, setCopied] = useState(false);

  const onDelete = async (eventId) => {
    try {
      await axios.delete(`${APIURL}events/${eventId}`);
      window.location.reload();
    } catch (error) {
      toast.error("Action failed!");
    }
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    console.log(event);
    setPage(newPage);
  };

  const viewEvent = async (eventId) => {
    await async_fetch_event(eventId, navigate);
  };

  return (
    <Card>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Actions</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Price (USD)</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {events.slice(0, limit).map((event, idx) => (
                <TableRow hover key={event._id}>
                  <TableCell>
                    <Chip
                      label="Edit / View"
                      clickable
                      style={{
                        color: colors.orange[600],
                        borderColor: colors.orange[600],
                        marginRight: 5,
                      }}
                      onClick={() => viewEvent(event._id)}
                      variant="outlined"
                    />
                    {event.isApproved ? (
                      <CopyToClipboard
                        text={`https://tubayo.com/events/${event._id}`}
                        onCopy={() => {
                          setCopied(!copied);
                          toast.success("Copied!!");
                          setCopied(false);
                        }}
                      >
                        <Chip
                          label="Copy Event URL"
                          clickable
                          style={{
                            color: "#0d8487",
                            borderColor: "#0d8487",
                            marginRight: 5,
                          }}
                          variant="outlined"
                        />
                      </CopyToClipboard>
                    ) : null}
                    <Chip
                      label="Delete"
                      clickable
                      style={{
                        color: colors.red[600],
                        borderColor: colors.red[600],
                        marginRight: 5,
                      }}
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure you wish to delete this Event?"
                          )
                        )
                          onDelete(event._id);
                      }}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>{event.eventName}</TableCell>
                  <TableCell>{event.price}</TableCell>
                  <TableCell>{event.address}</TableCell>
                  <TableCell>{event.startDate.split("T")[0]}</TableCell>
                  <TableCell>{event.endDate.split("T")[0]}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={events.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
}

export default connect(null, { async_fetch_event })(EventListResults);
