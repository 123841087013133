import axios from "axios";
import { APIURL } from "../utils/misc";
import { SET_BOOKING, EVENT_BOOKINGS } from "./types";
import { toast } from "react-toastify";

export function setBooking(booking) {
  return {
    type: SET_BOOKING,
    booking,
  };
}

export function fetchBookings() {
  return async (dispatch) => {
    try {
      const res = await axios.get(`${APIURL}bookings/viewBookings`);
      dispatch(setBooking(res.data));
    } catch (error) {
      console.error("err", error);
    }
  };
}

export const addCalenderEvent = (data) => async () => {
  try {
    const host = localStorage.getItem("HOST_ID");
    await axios.post(`${APIURL}homeCalendarBookings/host/${host}`, {
      ...data,
      host,
    });
    window.location.reload();
  } catch (error) {
    toast.error("Error! Failed to update calenadar please try again.");
  }
};

const fetchCalendarEventsSuccess = (payload) => ({
  type: EVENT_BOOKINGS,
  payload,
});

export const fetchCalendarEventsAsync = () => async (dispatch) => {
  try {
    const host = localStorage.getItem("HOST_ID");
    const res = await axios.get(`${APIURL}homeCalendarBookings/host/${host}`);
    const events = res.data.data.map((item) => {
      const start = new Date(`${item.checkInDate}`);
      const end = new Date(`${item.checkOutDate}`);
      return {
        id: item._id,
        description: item.description,
        start,
        end,
        title: item.home?.homeTitle,
      };
    });
    dispatch(fetchCalendarEventsSuccess(events));
  } catch (error) {
    toast.error("Error! Failed to update calenadar please try again.");
  }
};

export const clearCalenderEvent = (eventId) => async () => {
  try {
    await axios.delete(`${APIURL}homeCalendarBookings/${eventId}`);
    window.location.reload();
  } catch (error) {
    toast.error("Error! Failed to update calenadar please try again.");
  }
};
