import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
// import { makeStyles } from "@material-ui/styles";
import {
  ListItem,
  ListItemIcon,
  Collapse,
  List,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles(() => ({
  item: {
    display: "flex",
    paddingTop: 7,
    paddingBottom: 7,
  },
  button: {
    color: "#0d8487",
    fontWeight: 500,
    justifyContent: "flex-start",
    letterSpacing: 0,
    padding: "7px 4px",
    textTransform: "none",
    width: "100%",
  },
  icon: {
    marginRight: "1px",
  },
  title: {
    marginRight: "auto",
  },
  nested: {
    paddingLeft: "5px",
  },
  active: {
    color: "#0d8487",
    "& $title": {
      fontWeight: 500,
    },
    "& $icon": {
      color: "#0d8487",
    },
  },
}));

const NavItem = ({ href, icon: Icon, title, options, className, ...rest }) => {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const [activeStyles, setActiveStyles] = React.useState({});

  const handleClick = () => {
    setOpen(!open);
    setActiveStyles({ color: "#0d8487", fontWeight: "bold" });
  };

  const handleNav = () => {
    setActiveStyles({ color: "#0d8487", fontWeight: "bold" });
    if (href === "/login") {
      localStorage.clear();
      window.open("https://tubayo.com");
      // navigate("/login", { replace: true });
    }
    navigate(`${href}`);
  };

  const handleOptionsNav = (to) => {
    if (to === "/login") {
      localStorage.clear();
      window.open("https://tubayo.com");
      // navigate("/login", { replace: true });
    } else {
      navigate(`${to}`);
    }
  };

  const classes = useStyles();

  const renderCollapseList = (items) => {
    return items.map((item, idx) => {
      const OptionIcon = item.icon;
      return (
        <ListItem
          button
          className={classes.nested}
          key={idx}
          onClick={() => handleOptionsNav(item.href)}
        >
          <ListItemIcon>
            <OptionIcon className={classes.icon} size="20" />
          </ListItemIcon>
          <ListItemText primary={item.title} />
        </ListItem>
      );
    });
  };

  return (
    <>
      <ListItem
        button
        className={clsx(classes.item, className)}
        style={activeStyles}
        disableGutters
        {...rest}
        onClick={options.length > 0 ? handleClick : handleNav}
      >
        <ListItemIcon>
          <Icon className={classes.icon} size="20" />
        </ListItemIcon>
        <ListItemText
          className={classes.title}
          style={activeStyles}
          primary={title}
        />
        {options.length > 0 ? (
          <>{open ? <ExpandLess /> : <ExpandMore />}</>
        ) : null}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {renderCollapseList(options)}
        </List>
      </Collapse>
    </>
    // <ListItem
    //   disableGutters
    //   sx={{
    //     display: "flex",
    //     py: 0,
    //   }}
    //   {...rest}
    // >
    //   <Button
    //     component={RouterLink}
    //     sx={{
    //       color: "text.secondary",
    //       fontWeight: "medium",
    //       justifyContent: "flex-start",
    //       letterSpacing: 0,
    //       py: 1.25,
    //       textTransform: "none",
    //       width: "100%",
    //       ...(active && {
    //         color: "primary.main",
    //       }),
    //       "& svg": {
    //         mr: 1,
    //       },
    //     }}
    //     to={href}
    //   >
    //     {Icon && <Icon size="20" />}
    //     <span>{title}</span>
    //   </Button>
    // </ListItem>
  );
};

NavItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
};

export default NavItem;
