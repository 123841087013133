import { LOCATION_TYPES } from "../../actions/types";

const INITIAL_STATE = {
  country_code: "",
  country_name: "",
  latitude: "",
  city: "",
  longitude: "",
  IPv4: "",
};

const locationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOCATION_TYPES.FETCH_LOCATION:
      return {
        ...state,
        country_code: action.payload.country_code,
        country_name: action.payload.country_name,
        city: action.payload.city,
        latitude: action.payload.latitude,
        longitude: action.payload.longitude,
        IPv4: action.payload.IPv4,
      };
    default:
      return state;
  }
};

export default locationReducer;
