import { AUTH_TYPES } from "../../actions/types";

const initState = [];
const forgotPasswordReducer = (state = initState, action) => {
	switch (action.type) {
		case AUTH_TYPES.FORGOTPASSWORD:
			return { ...state, msg: action.payload };
		case AUTH_TYPES.FORGOTPASSWORD_ERROR:
			return { ...state, errorMessage: action.payload };
		default:
			return state;
	}
};

export default forgotPasswordReducer;
