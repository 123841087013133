import Resizer from "react-image-file-resizer";

export const APIURL = "https://api.tubayo.com/api/v1/";
// export const APIURL = "http://localhost:8080/api/v1/";

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      400,
      400,
      "PNG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

export const compressedFile = async (file) => {
  const dataURI = await resizeFile(file);
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
  return new Blob([ia], { type: mimeString });
};

export const longDateFormat = (date) => {
  const newDate = date && date.replace(/["']/g, "");
  var d = new Date(newDate);
  return (
    ("0" + d.getDate()).slice(-2) +
    "/" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "/" +
    d.getFullYear() +
    " " +
    d.getHours() +
    ":" +
    d.getMinutes() +
    " " +
    (d.getHours() >= 12 ? "pm" : "am")
  );
};

export const calculate_total_transactions = (transactions) =>
  transactions.reduce((accu, val) => (accu += parseFloat(val.host_amount)), 0);

export const format_money = (amount, decimal = ".", thousands = ",") => {
  let decimalCount = 0;
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    const negativeSign = amount < 0 ? "-" : "";
    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;
    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
};

export const formatCurrency = (
  amount,
  decimalCount = 0,
  decimal = ".",
  thousands = ","
) => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
};

export const RULES_LIST = [
  "No parties or events allowed.",
  "No smoking allowed.",
  "No pets allowed.",
  "Suitable for toddlers and children under 12.",
  "No unregistered guests allowed. ",
  "Please don’t eat or drink in the bedrooms.",
  "Please respect the noise curfew.",
  "Please turn off the AC when you go out.",
  "Please respect check-in and check-out times. ",
  "Please take extra care of your keys. Lost keys incur a replacement fee.",
  "Please take care of the furnishings. You have to pay for damages that exceed the security deposit.",
  "Please don’t rearrange the furniture.",
  "Please do your dishes.",
  "Please take the trash out before you leave.",
  "No illegal substances allowed on the premises.",
];

export const AMENITIES_LIST = [
  "Wifi",
  "Free parking",
  "A pool",
  "A jacuzzi",
  "A kitchen",
  "Air conditioning",
  "Heating",
  "A washer",
  "TV or cable",
  "Smoke alarm",
  "Carbon monoxide alarm",
  "Fire extinguisher",
  "First-aid kit",
  "Emergency plan and local numbers",
  "Toilet paper",
  "Soap for hands and body",
  "One towel per guest",
  "Linens for each bed",
  "One pillow per guest",
  "Cleaning supplies",
  "A pet-friendly space",
  "Step-free entryway",
  "Wide entrances",
  "Wide hallways",
  "Accessible bathroom",
  "Extra toilet paper, linens, and towels",
  "Basic toiletries like shampoo and conditioner",
  "Dish soap and cleaning supplies",
  "Dining basics like a coffee maker, cooking utensils, dishes, and silverware",
  "Wine glasses",
  "Basic cooking supplies like salt, pepper, and oil",
  "Coffee, tea",
  "Light breakfast or snacks",
  "Hangers",
  "Adapters and chargers",
  "Fast and reliable wifi",
  "Laptop-friendly workspace",
  "Good lighting",
  "Fully equipped kitchens",
  "Office supplies",
  "A crib and high chair",
  "A bathtub",
  "Air conditioning",
  "A washer and/or dryer",
  "Extra cleaning supplies",
  "Furniture covers",
  "Bowls for pet food and water",
  "Towels to wipe off paws at the door",
];

export const COUNTRIES = [
  "Afghanistan",
  // capital: "Kabul",
  // },
  "Aland Islands",
  // capital: "Mariehamn",
  // },
  "Albania",
  // capital: "Tirana",
  // },
  "Algeria",
  // capital: "Algiers",
  // },
  "American Samoa",
  // capital: "Pago Pago",
  // },
  "Andorra",
  // capital: "Andorra la Vella",
  // },
  "Angola",
  // capital: "Luanda",
  // },
  "Anguilla",
  // capital: "The Valley",
  // },
  "Antarctica",
  // capital: "",
  // },
  "Antigua and Barbuda",
  // capital: "St. John's",
  // },
  "Argentina",
  // capital: "Buenos Aires",
  // },
  "Armenia",
  // capital: "Yerevan",
  // },
  "Aruba",
  // capital: "Oranjestad",
  // },
  "Australia",
  // capital: "Canberra",
  // },
  "Austria",
  // capital: "Vienna",
  // },
  "Azerbaijan",
  // capital: "Baku",
  // },
  "Bahamas",
  // capital: "Nassau",
  // },
  "Bahrain",
  // capital: "Manama",
  // },
  "Bangladesh",
  // capital: "Dhaka",
  // },
  "Barbados",
  // capital: "Bridgetown",
  // },
  "Belarus",
  // capital: "Minsk",
  // },
  "Belgium",
  // capital: "Brussels",
  // },
  "Belize",
  // capital: "Belmopan",
  // },
  "Benin",
  // capital: "Porto-Novo",
  // },
  "Bermuda",
  // capital: "Hamilton",
  // },
  "Bhutan",
  // capital: "Thimphu",
  // },
  "Bolivia",
  // capital: "Sucre",
  // },
  "Bonaire, Saint Eustatius and Saba ",
  // capital: "",
  // },
  "Bosnia and Herzegovina",
  // capital: "Sarajevo",
  // },
  "Botswana",
  // capital: "Gaborone",
  // },
  "Bouvet Island",
  // capital: "",
  // },
  "Brazil",
  // capital: "Brasilia",
  // },
  "British Indian Ocean Territory",
  // capital: "Diego Garcia",
  // },
  "British Virgin Islands",
  // capital: "Road Town",
  // },
  "Brunei",
  // capital: "Bandar Seri Begawan",
  // },
  "Bulgaria",
  // capital: "Sofia",
  // },
  "Burkina Faso",
  // capital: "Ouagadougou",
  // },
  "Burundi",
  // capital: "Bujumbura",
  // },
  "Cambodia",
  // capital: "Phnom Penh",
  // },
  "Cameroon",
  // capital: "Yaounde",
  // },
  "Canada",
  // capital: "Ottawa",
  // },
  "Cape Verde",
  // capital: "Praia",
  // },
  "Cayman Islands",
  // capital: "George Town",
  // },
  "Central African Republic",
  // capital: "Bangui",
  // },
  "Chad",
  // capital: "N'Djamena",
  // },
  "Chile",
  // capital: "Santiago",
  // },
  "China",
  // capital: "Beijing",
  // },
  "Christmas Island",
  // capital: "Flying Fish Cove",
  // },
  "Cocos (Keeling) Islands",
  // capital: "West Island",
  // },
  "Cocos Islands",
  // capital: "West Island",
  // },
  "Colombia",
  // capital: "Bogota",
  // },
  "Comoros",
  // capital: "Moroni",
  // },
  "Cook Islands",
  // capital: "Avarua",
  // },
  "Costa Rica",
  // capital: "San Jose",
  // },
  "Croatia",
  // capital: "Zagreb",
  // },
  "Cuba",
  // capital: "Havana",
  // },
  "Curacao",
  // capital: " Willemstad",
  // },
  "Cyprus",
  // capital: "Nicosia",
  // },
  "Czech Republic",
  // capital: "Prague",
  // },
  "Democratic Republic of the Congo",
  // capital: "Kinshasa",
  // },
  "Denmark",
  // capital: "Copenhagen",
  // },
  "Djibouti",
  // capital: "Djibouti",
  // },
  "Dominica",
  // capital: "Roseau",
  // },
  "Dominican Republic",
  // capital: "Santo Domingo",
  // },
  "Ecuador",
  // capital: "Quito",
  // },
  "Egypt",
  // capital: "Cairo",
  // },
  "El Salvador",
  // capital: "San Salvador",
  // },
  "Equatorial Guinea",
  // capital: "Malabo",
  // },
  "Eritrea",
  // capital: "Asmara",
  // },
  "Estonia",
  // capital: "Tallinn",
  // },
  "Ethiopia",
  // capital: "Addis Ababa",
  // },
  "Falkland Islands",
  // capital: "Stanley",
  // },
  "Faroe Islands",
  // capital: "Torshavn",
  // },
  "Fiji",
  // capital: "Suva",
  // },
  "Finland",
  // capital: "Helsinki",
  // },
  "France",
  // capital: "Paris",
  // },
  "French Polynesia",
  // capital: "Papeete",
  // },
  "French Southern Territories",
  // capital: "Port-aux-Francais",
  // },
  "Gabon",
  // capital: "Libreville",
  // },
  "Gambia",
  // capital: "Banjul",
  // },
  "Georgia",
  // capital: "Tbilisi",
  // },
  "Germany",
  // capital: "Berlin",
  // },
  "Ghana",
  // capital: "Accra",
  // },
  "Gibraltar",
  // capital: "Gibraltar",
  // },
  "Greece",
  // capital: "Athens",
  // },
  "Greenland",
  // capital: "Nuuk",
  // },
  "Grenada",
  // capital: "St. George's",
  // },
  "Guadeloupe",
  // capital: "Basse-Terre",
  // },
  "Guam",
  // capital: "Hagatna",
  // },
  "Guatemala",
  // capital: "Guatemala City",
  // },
  "Guernsey",
  // capital: "St Peter Port",
  // },
  "Guinea",
  // capital: "Conakry",
  // },
  "Guinea-Bissau",
  // capital: "Bissau",
  // },
  "Guyana",
  // capital: "Georgetown",
  // },
  "Haiti",
  // capital: "Port-au-Prince",
  // },
  "Heard Island and McDonald Islands",
  // capital: "",
  // },
  "Honduras",
  // capital: "Tegucigalpa",
  // },
  "Hong Kong",
  // capital: "Hong Kong",
  // },
  "Hungary",
  // capital: "Budapest",
  // },
  "Iceland",
  // capital: "Reykjavik",
  // },
  "India",
  // capital: "New Delhi",
  // },
  "Indonesia",
  // capital: "Jakarta",
  // },
  "Iran",
  // capital: "Tehran",
  // },
  "Iraq",
  // capital: "Baghdad",
  // },
  "Ireland",
  // capital: "Dublin",
  // },
  "Isle of Man",
  // capital: "Douglas, Isle of Man",
  // },
  "Israel",
  // capital: "Jerusalem",
  // },
  "Italy",
  // capital: "Rome",
  // },
  "Ivory Coast",
  // capital: "Yamoussoukro",
  // },
  "Jamaica",
  // capital: "Kingston",
  // },
  "Japan",
  // capital: "Tokyo",
  // },
  "Jersey",
  // capital: "Saint Helier",
  // },
  "Jordan",
  // capital: "Amman",
  // },
  "Kazakhstan",
  // capital: "Astana",
  // },
  "Kenya",
  // capital: "Nairobi",
  // },
  "Kiribati",
  // capital: "Tarawa",
  // },
  "Kosovo",
  // capital: "Pristina",
  // },
  "Kuwait",
  // capital: "Kuwait City",
  // },
  "Kyrgyzstan",
  // capital: "Bishkek",
  // },
  "Laos",
  // capital: "Vientiane",
  // },
  "Latvia",
  // capital: "Riga",
  // },
  "Lebanon",
  // capital: "Beirut",
  // },
  "Lesotho",
  // capital: "Maseru",
  // },
  "Liberia",
  // capital: "Monrovia",
  // },
  "Libya",
  // capital: "Tripolis",
  // },
  "Liechtenstein",
  // capital: "Vaduz",
  // },
  "Lithuania",
  // capital: "Vilnius",
  // },
  "Luxembourg",
  // capital: "Luxembourg",
  // },
  "Macau",
  // capital: "Macao",
  // },
  "Macedonia",
  // capital: "Skopje",
  // },
  "Madagascar",
  // capital: "Antananarivo",
  // },
  "Malawi",
  // capital: "Lilongwe",
  // },
  "Malaysia",
  // capital: "Kuala Lumpur",
  // },
  "Maldives",
  // capital: "Male",
  // },
  "Mali",
  // capital: "Bamako",
  // },
  "Malta",
  // capital: "Valletta",
  // },
  "Marshall Islands",
  // capital: "Majuro",
  // },
  "Martinique",
  // capital: "Fort-de-France",
  // },
  "Mauritania",
  // capital: "Nouakchott",
  // },
  "Mauritius",
  // capital: "Port Louis",
  // },
  "Mayotte",
  // capital: "Mamoudzou",
  // },
  "Mexico",
  // capital: "Mexico City",
  // },
  "Micronesia",
  // capital: "Palikir",
  // },
  "Moldova",
  // capital: "Chisinau",
  // },
  "Monaco",
  // capital: "Monaco",
  // },
  "Mongolia",
  // capital: "Ulan Bator",
  // },
  "Montenegro",
  // capital: "Podgorica",
  // },
  "Montserrat",
  // capital: "Plymouth",
  // },
  "Morocco",
  // capital: "Rabat",
  // },
  "Mozambique",
  // capital: "Maputo",
  // },
  "Myanmar",
  // capital: "Nay Pyi Taw",
  // },
  "Namibia",
  // capital: "Windhoek",
  // },
  "Nauru",
  // capital: "Yaren",
  // },
  "Nepal",
  // capital: "Kathmandu",
  // },
  "Netherlands",
  // capital: "Amsterdam",
  // },
  "New Caledonia",
  // capital: "Noumea",
  // },
  "New Zealand",
  // capital: "Wellington",
  // },
  "Nicaragua",
  // capital: "Managua",
  // },
  "Niger",
  // capital: "Niamey",
  // },
  "Nigeria",
  // capital: "Abuja",
  // },
  "Niue",
  // capital: "Alofi",
  // },
  "Norfolk Island",
  // capital: "Kingston",
  // },
  "North Korea",
  // capital: "Pyongyang",
  // },
  "Northern Mariana Islands",
  // capital: "Saipan",
  // },
  "Norway",
  // capital: "Oslo",
  // },
  "Oman",
  // capital: "Muscat",
  // },
  "Pakistan",
  // capital: "Islamabad",
  // },
  "Palau",
  // capital: "Melekeok",
  // },
  "Palestinian Territory",
  // capital: "East Jerusalem",
  // },
  "Panama",
  // capital: "Panama City",
  // },
  "Papua New Guinea",
  // capital: "Port Moresby",
  // },
  "Paraguay",
  // capital: "Asuncion",
  // },
  "Peru",
  // capital: "Lima",
  // },
  "Philippines",
  // capital: "Manila",
  // },
  "Pitcairn",
  // capital: "Adamstown",
  // },
  "Poland",
  // capital: "Warsaw",
  // },
  "Portugal",
  // capital: "Lisbon",
  // },
  "Puerto Rico",
  // capital: "San Juan",
  // },
  "Qatar",
  // capital: "Doha",
  // },
  "Republic of the Congo",
  // capital: "Brazzaville",
  // },
  "Romania",
  // capital: "Bucharest",
  // },
  "Russia",
  // capital: "Moscow",
  // },
  "Rwanda",
  // capital: "Kigali",
  // },
  "Réunion",
  // capital: "Saint-Denis",
  // },
  "Saint Barthelemy",
  // capital: "Gustavia",
  // },
  "Saint Helena",
  // capital: "Jamestown",
  // },
  "Saint Kitts and Nevis",
  // capital: "Basseterre",
  // },
  "Saint Lucia",
  // capital: "Castries",
  // },
  "Saint Martin",
  // capital: "Marigot",
  // },
  "Saint Pierre and Miquelon",
  // capital: "Saint-Pierre",
  // },
  "Saint Vincent and the Grenadines",
  // capital: "Kingstown",
  // },
  "Samoa",
  // capital: "Apia",
  // },
  "San Marino",
  // capital: "San Marino",
  // },
  "Sao Tome and Principe",
  // capital: "Sao Tome",
  // },
  "Saudi Arabia",
  // capital: "Riyadh",
  // },
  "Senegal",
  // capital: "Dakar",
  // },
  "Serbia",
  // capital: "Belgrade",
  // },
  "Seychelles",
  // capital: "Victoria",
  // },
  "Sierra Leone",
  // capital: "Freetown",
  // },
  "Singapore",
  // capital: "Singapur",
  // },
  "Sint Maarten",
  // capital: "Philipsburg",
  // },
  "Slovakia",
  // capital: "Bratislava",
  // },
  "Slovenia",
  // capital: "Ljubljana",
  // },
  "Solomon Islands",
  // capital: "Honiara",
  // },
  "Somalia",
  // capital: "Mogadishu",
  // },
  "South Africa",
  // capital: "Pretoria",
  // },
  "South Georgia and the South Sandwich Islands",
  // capital: "Grytviken",
  // },
  "South Korea",
  // capital: "Seoul",
  // },
  "South Sudan",
  // capital: "Juba",
  // },
  "Spain",
  // capital: "Madrid",
  // },
  "Sri Lanka",
  // capital: "Colombo",
  // },
  "Sudan",
  // capital: "Khartoum",
  // },
  "Suriname",
  // capital: "Paramaribo",
  // },
  "Svalbard and Jan Mayen",
  // capital: "Longyearbyen",
  // },
  "Swaziland",
  // capital: "Mbabane",
  // },
  "Sweden",
  // capital: "Stockholm",
  // },
  "Switzerland",
  // capital: "Berne",
  // },
  "Syria",
  // capital: "Damascus",
  // },
  "Taiwan",
  // capital: "Taipei",
  // },
  "Tajikistan",
  // capital: "Dushanbe",
  // },
  "Tanzania",
  // capital: "Dodoma",
  // },
  "Thailand",
  // capital: "Bangkok",
  // },
  "Timor-Leste",
  // capital: "Dili",
  // },
  "Togo",
  // capital: "Lome",
  // },
  "Tokelau",
  // capital: "",
  // },
  "Tonga",
  // capital: "Nuku'alofa",
  // },
  "Trinidad and Tobago",
  // capital: "Port of Spain",
  // },
  "Tunisia",
  // capital: "Tunis",
  // },
  "Turkey",
  // capital: "Ankara",
  // },
  "Turkmenistan",
  // capital: "Ashgabat",
  // },
  "Turks and Caicos Islands",
  // capital: "Cockburn Town",
  // },
  "Tuvalu",
  // capital: "Funafuti",
  // },
  "U.S. Virgin Islands",
  // capital: "Charlotte Amalie",
  // },
  "Uganda",
  // capital: "Kampala",
  // },
  "Ukraine",
  // capital: "Kiev",
  // },
  "United Arab Emirates",
  // capital: "Abu Dhabi",
  // },
  "United Kingdom",
  // capital: "London",
  // },
  "United States",
  // capital: "Washington",
  // },
  "United States Minor Outlying Islands",
  // capital: "",
  // },
  "Uruguay",
  // capital: "Montevideo",
  // },
  "Uzbekistan",
  // capital: "Tashkent",
  // },
  "Vanuatu",
  // capital: "Port Vila",
  // },
  "Vatican",
  // capital: "Vatican City",
  // },
  "Vatican City State (Holy See)",
  // capital: "Vatican City",
  // },
  "Venezuela",
  // capital: "Caracas",
  // },
  "Vietnam",
  // capital: "Hanoi",
  // },
  "Wallis and Futuna",
  // capital: "Mata Utu",
  // },
  "Western Sahara",
  // capital: "El-Aaiun",
  // },
  "Yemen",
  // capital: "Sanaa",
  // },
  "Zambia",
  // capital: "Lusaka",
  // },
  "Zimbabwe",
  // capital: "Harare",
  // },
];
