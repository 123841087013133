import { combineReducers } from "redux";
// import { reducer as formReducer } from "redux-form";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import addExperienceReducer from "./reducers/addExperienceReducer";
import authReducer from "./reducers/auth";
import bookingReducer from "./reducers/bookingReducer";
import customerReducers from "./reducers/customer.reducers";
import editExperience from "./reducers/editExperience";
import editHostReducer from "./reducers/editHost";
import eventReducer from "./reducers/event.reducers";
import experienceReducer from "./reducers/experienceReducer";
import fetchedExperience from "./reducers/fetchedExperience";
import forgotPasswordReducer from "./reducers/forgotPassword";
import homesReducer from "./reducers/homesReducer";
import locationReducers from "./reducers/location.reducers";
import notificationReducer from "./reducers/notificationReducer";
import resetPasswordReducer from "./reducers/resetPassword";
import transactionReducers from "./reducers/transaction.reducers";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["experiences", "homes", "fetchedExp", "edit_host"],
};

const rootReducer = combineReducers({
  // form: formReducer,
  auth: authReducer,
  experiences: experienceReducer,
  booking: bookingReducer,
  add_experience: addExperienceReducer,
  fetchedExp: fetchedExperience,
  edit_experience: editExperience,
  forgot_password: forgotPasswordReducer,
  reset_password: resetPasswordReducer,
  edit_host: editHostReducer,
  location: locationReducers,
  homes: homesReducer,
  customers: customerReducers,
  transactionReducers,
  notificationReducer,
  eventReducer,
});

export default persistReducer(persistConfig, rootReducer);
