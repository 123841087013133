import axios from "axios";
import { toast } from "react-toastify";
import { APIURL } from "../utils/misc";
import { AUTH_TYPES } from "./types";

export const signin = (user, navigate) => {
  return async (dispatch) => {
    try {
      if (user.length > 0 && user !== ":user") {
        const res = await axios.get(`${APIURL}hosts/checkHost/${user}`);
        if (res.data.message) {
          dispatch(
            toast.warn(
              "Sorry no host account exists for your user account, Kindly link host account to user account or create new host account."
            )
          );
          navigate(`/link_account/${user}`, { replace: true });
        } else {
          dispatch({
            type: AUTH_TYPES.AUTH_SIGN_IN,
            payload: res.data.data,
          });
          localStorage.setItem("JWT_TOKEN", res.data.data.token);
          localStorage.setItem("HOST_ID", res.data.data.hostId);
          localStorage.setItem("USERNAME", res.data.data.userName);
          localStorage.setItem("PHOTO", res.data.data.photo);
          navigate("/app/dashboard", { replace: true });
        }
      }
    } catch (error) {}
  };
};

export const linkHostAccountAsync =
  (user, data, navigate) => async (dispatch) => {
    try {
      const res = await axios.patch(`${APIURL}hosts/link/${user}`, data);
      if (res.data.message) {
        dispatch(
          toast.error(
            "Sorry, there is no host account with that email. Kindly contact admin for help or create a new host account"
          )
        );
      } else {
        dispatch(toast.success("Accounts linked successfully!"));
        dispatch({
          type: AUTH_TYPES.AUTH_SIGN_IN,
          payload: res.data,
        });
        localStorage.setItem("JWT_TOKEN", res.data.data.token);
        localStorage.setItem("HOST_ID", res.data.data.hostId);
        localStorage.setItem("USERNAME", res.data.data.userName);
        localStorage.setItem("PHOTO", res.data.data.photo);

        navigate("/app/dashboard", { replace: true });
      }
    } catch (error) {
      if (error.message === "Network Error") {
        dispatch(toast.error("Please check your network connection."));
      } else {
        dispatch(toast.error("Invalid email address or password!"));
      }
    }
  };

export const forgotPassword = (data) => {
  return async () => {
    try {
      const res = await axios.post(`${APIURL}hosts/forgot_password`, data);
      if (
        res.data.message ===
        "A verification email has been sent to your email account. Please verify your account to login."
      ) {
        toast.success(
          "A verification email has been sent to your email account. Please verify your account to login."
        );
      }
    } catch (err) {
      toast.error("We are unable to find user with email");
    }
  };
};

export const reset_password = (data, navigate) => {
  return async () => {
    try {
      const res = await axios.post(`${APIURL}hosts/confirm_token`, data);
      if (res.data.message === "Password has been reset successfully.") {
        toast.success(
          "Password has been reset successfully, Please login with your new password."
        );
      }
      navigate("/login", { replace: true });
    } catch (err) {
      toast.error(
        "Token provided is expired or doesn't exsit, please try again."
      );
      navigate("/login", { replace: true });
    }
  };
};

export const signOut = () => {
  return (dispatch) => {
    localStorage.removeItem("JWT_TOKEN");
    localStorage.removeItem("HOST_ID");
    localStorage.removeItem("PHOTO");
    localStorage.removeItem("USERNAME");
    dispatch({
      type: AUTH_TYPES.AUTH_SIGN_OUT,
      payload: "",
    });
  };
};
