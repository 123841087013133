import { useEffect, useState, Fragment } from "react";
import { Helmet } from "react-helmet";
import {
  Box,
  Container,
  Card,
  CardHeader,
  CardContent,
  Button,
  Grid,
  InputLabel,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Select,
  FormControl,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@material-ui/core";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { fetchHomes } from "src/actions/HomeActions";
import { useDispatch, useSelector } from "react-redux";
import {
  addCalenderEvent,
  clearCalenderEvent,
  fetchCalendarEventsAsync,
} from "src/actions/BookingActions";
import { toast } from "react-toastify";
import { Close, Delete } from "@material-ui/icons";

const CustomEvent = ({ event }) => {
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const handleDialogOpen = () => {
    setOpenDialog(true);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  const handleDeleteBooking = (eventId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this booking?"
    );
    if (confirmDelete) {
      dispatch(clearCalenderEvent(eventId))
        .then(() => {
          toast.success("Booking deleted successfully");
        })
        .catch((error) => {
          toast.error("Failed to delete booking");
        });
    }
  };
  return (
    <div
      style={{
        borderRadius: "5px",
        backgroundColor: "teal",
        padding: 5,
        borderColor: "teal",
      }}
      onClick={handleDialogOpen}
    >
      <Fragment>{`${event.description} for ${event.title}`}</Fragment>
      <small>
        <i> (Click to view details)</i>
      </small>
      <Dialog open={openDialog} onClose={handleDialogClose} fullWidth>
        <IconButton
          aria-label="close"
          onClick={(e) => {
            e.stopPropagation(); // Prevent event propagation
            handleDialogClose();
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
        <DialogTitle>Booking Details</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Home </TableCell>
                  <TableCell>
                    <InputLabel color={"primary"}>{event.title}</InputLabel>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Description</TableCell>
                  <TableCell>
                    <InputLabel color={"primary"}>
                      {event.description}
                    </InputLabel>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Checkin Date</TableCell>
                  <TableCell>
                    <InputLabel color={"info"}>
                      {new Date(event.start).toDateString()}
                    </InputLabel>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Checkout Date</TableCell>
                  <TableCell>
                    <InputLabel color={"info"}>
                      {new Date(event.end).toDateString()}
                    </InputLabel>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Action</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleDeleteBooking(event.id)}
                    >
                      <Delete />
                      Delete Booking
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const CreateEventDialog = ({
  homes,
  openDialog,
  handleClose,
  handleChange,
  handleSubmit,
}) => (
  <Dialog open={openDialog} onClose={handleClose} fullWidth>
    <DialogTitle>Add Calendar Details</DialogTitle>
    <DialogContent>
      <form onSubmit={handleSubmit}>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Select Home</InputLabel>
              <Select onChange={handleChange} name="home">
                {homes.map((item) => (
                  <MenuItem value={item._id}>{item.homeTitle}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Message/Reason"
              multiline
              minRows={2}
              fullWidth
              name="description"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Start Date"
              type="date"
              name="checkInDate"
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="End Date"
              type="date"
              name="checkOutDate"
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Button variant="outlined" color="primary" type="submit">
              Submit
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="outlined" color="secondary" onClick={handleClose}>
              Close
            </Button>
          </Grid>
        </Grid>
      </form>
    </DialogContent>
  </Dialog>
);

function HomeCalendar() {
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [formState, setFormState] = useState({});

  useEffect(() => {
    dispatch(fetchHomes());
    dispatch(fetchCalendarEventsAsync());
    // eslint-disable-next-line
  }, []);

  const { homes } = useSelector((state) => state.homes);
  const { homeBookingEvents } = useSelector((state) => state.booking);

  const localizer = momentLocalizer(moment);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((formState) => ({ ...formState, [name]: value }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addCalenderEvent(formState));
    setOpenDialog(false);
  };

  return (
    <>
      <Helmet>
        <title>Tubayo Host | Home calendar</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Card>
            <CardHeader subheader="Manage Calendar" />
            <CardContent style={{ height: "75vh" }}>
              <Button
                variant="outlined"
                onClick={() => setOpenDialog(true)}
                color="primary"
              >
                Update Calendar
              </Button>
              <Box sx={{ mb: 5, mt: 2 }}>
                <Calendar
                  localizer={localizer}
                  selectable
                  events={homeBookingEvents}
                  style={{ height: "500px" }}
                  startAccessor="start"
                  endAccessor="end"
                  components={{
                    event: ({ event }) => <CustomEvent event={event} />, //
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
      <CreateEventDialog
        openDialog={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleChange={handleChange}
        homes={homes}
        handleSubmit={handleSubmit}
      />
    </>
  );
}

export default HomeCalendar;
