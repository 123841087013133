import { EXPERIENCE_TYPE } from '../../actions/types';

const INITIAL_STATE = {
    experience: []
}

const experienceReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case EXPERIENCE_TYPE.SET_EXPERIENCE:
            return { ...state, experience: [...action.experience] }
        default:
            return state
    }
}

export default experienceReducer