import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Chip,
  TextField,
  FormGroup,
} from "@material-ui/core";
import axios from "axios";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import { connect } from "react-redux";
import {
  uploadExperienceCoverImage,
  deleteImageFile,
} from "src/actions/ExperienceActions";
import {
  editHost,
  onAddSubHost,
  fetctHostProfile,
  onRemoveSubHost,
} from "src/actions/EditHost";
import { Autocomplete } from "@mui/material";
import { APIURL } from "src/utils/misc";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginImagePreview
);

const AccountProfile = ({
  host,
  subHostOptions,
  uploadExperienceCoverImage,
  deleteImageFile,
  editHost,
  onAddSubHost,
  fetctHostProfile,
  onRemoveSubHost,
  ...rest
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openSubHost, setOpenSubHost] = useState(false);
  const [inputVal, setInputVal] = useState("l");
  const [subHost, setSubHost] = useState({});
  const [cover, setCover] = useState([]);
  const [cover_file, setCoverFile] = useState({});
  const [options, setOptions] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickSubHost = () => {
    setOpenSubHost(true);
  };

  const handleClose = async () => {
    setOpen(false);
    if (cover.length > 0) {
      await deleteImageFile({ Key: cover_file.Key });
      setCover([]);
      setCoverFile({});
    }
  };

  useEffect(() => {
    fetctHostProfile();
  }, [fetctHostProfile]);

  const handleCloseSub = () => setOpenSubHost(false);

  const handleUpdate = async () => {
    setOpen(false);
    const obj = {
      ...host,
      profilePic: cover_file.path,
    };
    await editHost(obj, host?._id, navigate);
  };

  useEffect(() => {
    const checkUserOptions = async () => {
      try {
        const res = await axios.get(`${APIURL}users/search?q=${inputVal}`);
        setOptions(
          res.data.data.map((item) => ({ _id: item._id, ...item[item.method] }))
        );
      } catch (error) {}
    };
    checkUserOptions();
  }, [inputVal]);

  const handleSubmit = () => {
    onAddSubHost(subHost._id);
  };

  return (
    <>
      <Card {...rest}>
        <CardContent>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Avatar
              src={host?.profilePic}
              style={{
                height: 100,
                width: 100,
              }}
            />
            <Typography color="textPrimary" gutterBottom variant="h3">
              {`${host?.firstName} ${host?.lastName}`}
            </Typography>
            <Typography color="textSecondary" variant="body1">
              {host?.location}
            </Typography>
            <Typography color="textSecondary" variant="body1">
              {host?.email}
            </Typography>
          </Box>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            fullWidth
            variant="text"
            onClick={handleClickOpen}
          >
            Upload picture
          </Button>
        </CardActions>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth={"md"}
        >
          <DialogTitle id="form-dialog-title">Upload Profile Photo</DialogTitle>
          <DialogContent style={{ width: "80%", height: "90%" }}>
            <Grid item md={12}>
              <FilePond
                files={cover}
                onupdatefiles={setCover}
                allowMultiple={false}
                allowImageResize={true}
                allowRemove={true}
                acceptedFileTypes={["image/*"]}
                server={{
                  process: async (
                    fieldName,
                    file,
                    metadata,
                    load,
                    error,
                    progress,
                    abort
                  ) => {
                    try {
                      const formData = new FormData();
                      formData.append("imageCover", file);
                      const res = await uploadExperienceCoverImage(formData);
                      setCoverFile(res.file_data);
                      load(res.message);
                    } catch (err) {
                      console.log(error);
                      error(err.message);
                    }
                    return {
                      abort: () => {
                        abort();
                      },
                    };
                  },
                  revert: async (uniqueFileId, load) => {
                    const keyObject = { Key: cover_file.Key };
                    try {
                      const res = await deleteImageFile(keyObject);
                      setCoverFile({});
                      load(res.message);
                    } catch (error) {
                      console.log(error);
                      load();
                    }
                  },
                }}
                name="file"
                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleUpdate} color="primary">
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
      <Card style={{ marginTop: 10 }}>
        <CardContent>
          <Typography variant="h4" style={{ paddingBottom: 10 }}>
            Sub host Accounts
          </Typography>
          {}
          {host?.subHosts.map((item, idx) => (
            <Chip
              key={idx}
              label={item[item.method].userName}
              onDelete={() => onRemoveSubHost(item._id)}
              color="primary"
              style={{ margin: 4 }}
            />
          ))}
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            fullWidth
            variant="text"
            onClick={handleClickSubHost}
          >
            Add SubHost
          </Button>
        </CardActions>
        <Dialog
          open={openSubHost}
          onClose={handleCloseSub}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth={"md"}
        >
          <DialogTitle id="form-dialog-title">
            Search for user by username.
          </DialogTitle>
          <DialogContent style={{ width: "80%", height: "90%" }}>
            <Grid item xs={12}>
              <FormGroup>
                <Autocomplete
                  disableClearable
                  fullWidth
                  options={options}
                  onChange={(event, newValue) => {
                    setSubHost(newValue);
                  }}
                  inputValue={inputVal}
                  onInputChange={(event, newInputValue) => {
                    setInputVal(newInputValue);
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option.userName}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Select sub host name"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </FormGroup>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSub} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmit} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  host: state.edit_host?.host,
  subHostOptions: state.edit_host.subHostOptions,
});

export default connect(mapStateToProps, {
  uploadExperienceCoverImage,
  deleteImageFile,
  editHost,
  onAddSubHost,
  fetctHostProfile,
  onRemoveSubHost,
})(AccountProfile);
