import Axios from "axios";
import { toast } from "react-toastify";
import { APIURL } from "../utils/misc";
import { CUSTOMER_TYPES } from "./types";

const fetch_customers_success = (payload) => ({
  type: CUSTOMER_TYPES.FETCH_CUSTOMERS_SUCCESS,
  payload,
});

export const fetch_customers_async = () => async (dispatch) => {
  const host = localStorage.getItem("HOST_ID");
  try {
    const res = await Axios.get(`${APIURL}hosts/${host}/customer`);
    dispatch(fetch_customers_success(res.data));
  } catch (error) {
    console.log("fetch customer", error.message);
  }
};

const add_customer_success = (payload) => ({
  type: CUSTOMER_TYPES.ADD_CUSTOMER_SUCCESS,
  payload,
});

export const add_customer_async = (data, navigate) => async (dispatch) => {
  const host = localStorage.getItem("HOST_ID");
  try {
    const res = await Axios.post(`${APIURL}hosts/${host}/customer`, data);
    dispatch(add_customer_success(res.data));
    toast.success("Customer added successfully🥳🥳.");
    navigate("/app/customers", { replace: true });
    window.location.reload();
  } catch (error) {
    toast.error(error.message);
    console.log("fetch customer", error.message);
  }
};
