import { SET_BOOKING, EVENT_BOOKINGS } from "../../actions/types";

const initialState = {
  bookings: [],
  homeBookingEvents: [],
};

const bookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BOOKING:
      return { ...state, bookings: action.booking };
    case EVENT_BOOKINGS:
      return {
        ...state,
        homeBookingEvents: action.payload,
      };
    default:
      return state;
  }
};

export default bookingReducer;
