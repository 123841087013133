import axios from "axios";
import { toast } from "react-toastify";
import { APIURL } from "src/utils/misc";
const { EVENT_TYPE } = require("./types");

export const async_create_event = (data, navigate) => async (dispatch) => {
  try {
    const hostId = localStorage.getItem("HOST_ID");
    await axios.post(`${APIURL}events/${hostId}/event`, data);
    navigate("/app/events");
    toast.success("Event created successfully!");
  } catch (error) {
    toast.error(error.message);
  }
};

export const uploadEventCoverImage = (data) => async () => {
  try {
    const res = await axios.post(`${APIURL}experiences/uploadCover`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return res.data;
  } catch (error) {
    if (error.message === "Network Error") {
      toast.error("Large Image size, please add image with atmost 1MB.");
    } else {
      toast.error("File upload failed!");
    }
  }
};

export const deleteImageFile = (data) => async () => {
  try {
    const res = await axios.post(`${APIURL}experiences/deleteImage`, data);
    return res.data;
  } catch (error) {
    toast.error("Action  failed!");
  }
};

const fetch_events_success = (payload) => ({
  type: EVENT_TYPE.GET_EVENTS,
  payload,
});

export const async_fetch_events = () => async (dispatch) => {
  try {
    const hostId = localStorage.getItem("HOST_ID");
    const res = await axios.get(`${APIURL}events/${hostId}/event`);
    dispatch(fetch_events_success(res.data.events));
  } catch (error) {
    toast.error(error.message);
  }
};

const fetch_event_success = (payload) => ({
  type: EVENT_TYPE.GET_EVENT,
  payload,
});

export const async_fetch_event = (eventId, navigate) => async (dispatch) => {
  try {
    const res = await axios.get(`${APIURL}events/admin/${eventId}`);
    dispatch(fetch_event_success(res.data.event));
    navigate("/app/event/edit");
  } catch (error) {
    toast.error(error.message);
  }
};

export const async_update_event =
  (data, eventId, navigate) => async (dispatch) => {
    try {
      await axios.patch(`${APIURL}events/${eventId}`, data);
      navigate("/app/events");
      toast.success("Event updated successfully.");
    } catch (error) {
      toast.error(error.message);
    }
  };

const eventBookingFetchSuccess = (payload) => ({
  type: EVENT_TYPE.EVENT_BOOKING_SUCCESS,
  payload,
});

export const eventBookingFetchAsync = () => async (dispatch) => {
  try {
    const host = localStorage.getItem("HOST_ID");
    const res = await axios.get(`${APIURL}bookEvents/host/${host}`);
    dispatch(eventBookingFetchSuccess(res.data.booking));
  } catch (error) {
    toast.error(error.message);
  }
};

export const sendComplementaryTicket = (data) => async () => {
  try {
    await axios.post(`${APIURL}bookEvents/complimentary`, data);
    toast.success("Ticket sent successfully!");
  } catch (error) {
    toast.error(error.message);
  }
};
