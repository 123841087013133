import { Helmet } from "react-helmet";
import { useParams, useNavigate } from "react-router";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from "@material-ui/core";
import { connect } from "react-redux";
import { linkHostAccountAsync } from "src/actions/loginAction";

const ForgotPassword = ({ linkHostAccountAsync }) => {
  const { user } = useParams();
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Tubayo Host | Forgot Password</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="sm">
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <img
              alt="banare"
              style={{ width: "30%", height: "30%" }}
              src="/static/images/banna.png"
            />
          </Box>
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
            })}
            onSubmit={async (values) => {
              linkHostAccountAsync(user, values, navigate);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  sx={{
                    mb: 3,
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Typography
                    color="textPrimary"
                    variant="h2"
                    justifyContent="center"
                  >
                    Link Accounts
                  </Typography>
                </Box>

                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  placeholder="Enter email address for your host account here"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <Box
                  sx={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    display: "flex",
                    ml: -1,
                  }}
                >
                  <Typography color="textSecondary" variant="body1">
                    Don't have the host Account?{" "}
                    <a
                      href="https://tubayo.com/host"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "teal",
                        textDecoration: "underline",
                        fontWeight: "bold",
                      }}
                    >
                      Become Host
                    </a>
                  </Typography>
                </Box>
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Submit
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default connect(null, { linkHostAccountAsync })(ForgotPassword);
