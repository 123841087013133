import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Box, Container } from "@material-ui/core";
import HomeListResults from "src/components/homes/HomeListResults";
import { connect } from "react-redux";
// import HomeListToolbar from "src/components/homes/HomeListToolbar";
import { fetchHomes } from "src/actions/HomeActions";

const HomeList = ({ fetchHomes, homes }) => {
  useEffect(() => {
    const fetchItemsAsync = async () => {
      await fetchHomes();
    };
    fetchItemsAsync();
  }, [fetchHomes]);

  return (
    <>
      <Helmet>
        <title>Tubayo Host | Home</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          {/* <HomeListToolbar /> */}
          <Box sx={{ pt: 3 }}>
            <HomeListResults homes={homes} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  homes: state.homes.homes,
});

export default connect(mapStateToProps, { fetchHomes })(HomeList);
