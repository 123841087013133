import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Grid,
  TextField,
  Container,
  Button,
  colors,
  Card,
  CardHeader,
  CardContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import SecurityIcon from "@material-ui/icons/Security";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { addExperience } from "src/actions/ExperienceActions";
import PlacesAutocomplete from "react-places-autocomplete";
import { Add, Cancel } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  root: {
    padding: 3,
    marginTop: 30,
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: colors.grey[300],
  },
  content: {
    marginTop: 20,
  },
  actions: {
    justifyContent: "center",
  },
  input: {
    display: "none",
  },
  avatar: {
    height: 150,
    width: 150,
    borderRadius: 0,
  },
  saveButton: {
    color: "#fff",
    backgroundColor: colors.green[600],
    "&:hover": {
      backgroundColor: colors.green[900],
    },
    width: "30%",
  },
}));

const options = [
  "Art and design",
  "Culture and history",
  "Social impact",
  "Life and style",
  "Comedy",
  "Dance",
  "Gaming",
  "Magic",
  "Movies, TV, or radio",
  "Music",
  "Nightlife",
  "Drink",
  "Food",
  "Animals",
  "Outdoor activities",
  "Adrenaline sports",
  "Combat sports",
  "Fitness and recreation",
  "Mountain sports",
  "Water sports",
  "Wellness",
  "Adventures – Multi Day trips",
  "Safari",
];

function CreateExperience({ addExperience }) {
  const [formState, setFormState] = React.useState({
    experienceCategory: "Art and design",
    experienceTitle: "",
    country: "",
    language: "",
    brifeDescription: "",
    experienceDescription: "",
    whatYouProvide: "",
    whatGuestsNeedToKno: "",
    price: 0.0,
    minGroupSize: 1,
    maxGroupSize: 1000,
  });
  const [location, setLocation] = React.useState("");
  const [variant, setVariant] = React.useState([
    {
      category: "Adult",
      variantQty: 0,
      price: 1,
    },
  ]);
  const [coHostState, setCoHostState] = React.useState({
    co_hostNames: "",
    co_hostEmail: "",
    co_hostPhoneNumber: "",
  });
  const navigate = useNavigate();
  const classes = useStyles();

  let handleChangeVariant = (i, e) => {
    let newVariant = [...variant];
    newVariant[i][e.target.name] = e.target.value;
    setVariant(newVariant);
  };

  let addFormFields = () => {
    setVariant([
      ...variant,
      {
        category: "",
        price: 0,
        variantQty: 0,
      },
    ]);
  };

  let removeFormFields = (i) => {
    let newVariant = [...variant];
    newVariant.splice(i, 1);
    setVariant(newVariant);
  };

  return (
    <Container className={classes.root}>
      <Card>
        <CardHeader subheader="Add Experience Details" title="Experience" />
        <Divider className={classes.divider} />
        <CardContent style={{ marginTop: 20 }}>
          <Formik
            initialValues={formState}
            validationSchema={Yup.object().shape({
              experienceTitle: Yup.string().required(
                "Experience Title is required"
              ),
              country: Yup.string().required("Country is required"),
              // location: Yup.string().required("Location is required"),
              language: Yup.string().required("Language is required"),
              brifeDescription: Yup.string().required(
                "Please add brief description"
              ),
              experienceDescription: Yup.string().required(
                "Please add brief description"
              ),
              whatYouProvide: Yup.string().required(
                "Please add What you will provide"
              ),
              whatGuestsNeedToKno: Yup.string().required(
                "Please add What should guests bring with them"
              ),
              price: Yup.number().required("The price should be Number"),
              minGroupSize: Yup.number(),
              maxGroupSize: Yup.number(),
            })}
            onSubmit={async (values, { resetForm }) => {
              const obj = {
                ...values,
                experienceCategory: formState.experienceCategory,
                location,
                availableDates: [],
                co_host: coHostState,
                variant,
              };
              await addExperience(obj, navigate);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form className={classes.form} onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(
                        touched.experienceTitle && errors.experienceTitle
                      )}
                      helperText={
                        touched.experienceTitle && errors.experienceTitle
                      }
                      variant="outlined"
                      fullWidth
                      label="Give your experience a Title"
                      name="experienceTitle"
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="What experience would you like to host on Tubayo (Ex. Learn the simple art of dairy farming)"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      style={{ minWidth: "100%" }}
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Select the Category of the experience you will host?
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        onChange={(e) =>
                          setFormState({
                            ...formState,
                            experienceCategory: e.target.value,
                          })
                        }
                        label="Select the Category of the experience you will host?"
                        autoWidth
                      >
                        {options.map((item) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      error={Boolean(touched.country && errors.country)}
                      helperText={touched.country && errors.country}
                      variant="outlined"
                      fullWidth
                      label="Country"
                      name="country"
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <PlacesAutocomplete
                      value={location}
                      onChange={setLocation}
                      onSelect={setLocation}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <TextField
                            {...getInputProps({
                              variant: "outlined",
                              placeholder: "Add Location",
                              className: "form-control m-2",
                              style: { height: "50px", width: "100%" },
                            })}
                          />
                          <div>
                            {loading ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginTop: 10,
                                }}
                              >
                                <div className="loader" />
                              </div>
                            ) : null}
                            {suggestions.map((suggestion) => {
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    style: {
                                      backgroundColor: suggestion.active
                                        ? "#ececec"
                                        : "#fff",
                                      padding: 5,
                                      marginTop: 10,
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    },
                                  })}
                                >
                                  <LocationCityIcon
                                    style={{ marginRight: 10 }}
                                  />
                                  <Typography variant="inherit">
                                    {suggestion.description}
                                  </Typography>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      error={Boolean(touched.language && errors.language)}
                      helperText={touched.language && errors.language}
                      variant="outlined"
                      fullWidth
                      label="What language will you host in?"
                      name="language"
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="You should be able to read, write, and speak in this language. You’ll be able to add additional languages next. (Ex. English and German)"
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      error={Boolean(
                        touched.brifeDescription && errors.brifeDescription
                      )}
                      helperText={
                        touched.brifeDescription && errors.brifeDescription
                      }
                      variant="outlined"
                      fullWidth
                      label="Experience Title description"
                      name="brifeDescription"
                      type="text"
                      placeholder="Make it short, descriptive, and exciting (Ex. You will get to learn all the farming processes)"
                      min={0}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(
                        touched.experienceDescription &&
                          errors.experienceDescription
                      )}
                      helperText={
                        touched.experienceDescription &&
                        errors.experienceDescription
                      }
                      variant="outlined"
                      fullWidth
                      label="Describe the experience, what you’ll do, what guest should know?"
                      name="experienceDescription"
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Your activity description is a chance to inspire guests to take your experience. Talk about the details of the itinerary you have planned for them."
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(
                        touched.whatYouProvide && errors.whatYouProvide
                      )}
                      helperText={
                        touched.whatYouProvide && errors.whatYouProvide
                      }
                      variant="outlined"
                      fullWidth
                      label="Add details about what you’ll provide"
                      name="whatYouProvide"
                      type="text"
                      placeholder="List all that you will provide to make your guests comfortable and the experience memorable. (Ex. Food and drink, special equipment, a ticket to a concert)"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(
                        touched.whatGuestsNeedToKno &&
                          errors.whatGuestsNeedToKno
                      )}
                      helperText={
                        touched.whatGuestsNeedToKno &&
                        errors.whatGuestsNeedToKno
                      }
                      variant="outlined"
                      fullWidth
                      id="whatGuestsNeedToKno"
                      label="What should guests bring with them?"
                      name="whatGuestsNeedToKno"
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Think through everything a guest will need to have with them during the experience that you’re not already providing. (Ex. ID, umbrella in case of weather, hiking shoes if it’s a hike)"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.price && errors.price)}
                      helperText={touched.price && errors.price}
                      variant="outlined"
                      fullWidth
                      label="Set the experience base price per person? (USD)"
                      name="price"
                      min={1}
                      type="number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Catch guests’ attention with a listing title that highlights what makes your place special."
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(
                        touched.minGroupSize && errors.minGroupSize
                      )}
                      helperText={touched.minGroupSize && errors.minGroupSize}
                      variant="outlined"
                      fullWidth
                      label="Set minimum group size.(Optional)"
                      name="minGroupSize"
                      min={1}
                      type="number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Minimum number of guests required to do the Experience."
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(
                        touched.maxGroupSize && errors.maxGroupSize
                      )}
                      helperText={touched.maxGroupSize && errors.maxGroupSize}
                      variant="outlined"
                      fullWidth
                      label="Set maximum group size.(Optional)"
                      name="maxGroupSize"
                      min={1}
                      type="number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Maximum number of guests required for the Experience."
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5">
                      Set Experience Price categories
                    </Typography>
                    {variant.map((item, idx) => (
                      <Box style={{ marginTop: 6 }}>
                        <FormControl
                          style={{ minWidth: "100%" }}
                          variant="outlined"
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Select Variant Category
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            style={{ marginBottom: 17 }}
                            onChange={(e) => handleChangeVariant(idx, e)}
                            name="category"
                            label="Select Variant Category"
                            autoWidth
                          >
                            {["Adult", "Children", "Group"].map((item) => (
                              <MenuItem key={item} value={item}>
                                {item}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {item.category === "Group" && (
                          <TextField
                            style={{ marginBottom: 17 }}
                            variant="outlined"
                            fullWidth
                            label="Number of people"
                            name="variantQty"
                            type="text"
                            onChange={(e) => handleChangeVariant(idx, e)}
                          />
                        )}
                        <TextField
                          variant="outlined"
                          fullWidth
                          id="price"
                          label={
                            item.category === "Children"
                              ? "What is the cost of the experience for children aged 2-12 ? (USD)"
                              : "What is the cost of the experience? (USD)"
                          }
                          name="price"
                          min={1}
                          onChange={(e) => handleChangeVariant(idx, e)}
                          placeholder="Catch guests’ attention with a listing title that highlights what makes your place special."
                        />
                      </Box>
                    ))}
                    <Box>
                      {variant.length < 3 ? (
                        <Button
                          variant="contained"
                          color="secondary"
                          style={{ marginTop: 5 }}
                          startIcon={<Add />}
                          onClick={addFormFields}
                        >
                          Add variant
                        </Button>
                      ) : null}
                      {variant.length > 1 ? (
                        <Button
                          variant="contained"
                          color="default"
                          style={{ marginTop: 5, marginLeft: 10 }}
                          startIcon={<Cancel />}
                          onClick={removeFormFields}
                        >
                          Remove variant
                        </Button>
                      ) : null}
                    </Box>
                  </Grid>

                  {/* <Grid item xs={12} /> */}

                  <Typography
                    variant="h6"
                    style={{ marginLeft: 10, marginTop: 20, color: "#0d8487" }}
                  >
                    Add Co-Host Information. (This could be the experience
                    guide.) Optional
                  </Typography>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="co_hostNames"
                      label="Add Co-Host Full Names (If the you have one)"
                      name="co_hostNames"
                      type="text"
                      onChange={(e) =>
                        setCoHostState({
                          ...coHostState,
                          co_hostNames: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="co_hostEmail"
                      label="Add Co-Host Email Address"
                      name="co_hostEmail"
                      type="text"
                      onChange={(e) =>
                        setCoHostState({
                          ...coHostState,
                          co_hostEmail: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="co_hostPhoneNumber"
                      label="Add Co-Host Phone Number"
                      name="co_hostPhoneNumber"
                      type="text"
                      onChange={(e) =>
                        setCoHostState({
                          ...coHostState,
                          co_hostPhoneNumber: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12}></Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  startIcon={<SecurityIcon />}
                >
                  Continue to add images
                </Button>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Container>
  );
}

export default connect(null, { addExperience })(CreateExperience);
