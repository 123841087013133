import { CUSTOMER_TYPES } from "../../actions/types";

const INIT_STATE = {
  customers: [],
};

const customerReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CUSTOMER_TYPES.FETCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: action.payload.customers,
      };
    case CUSTOMER_TYPES.ADD_CUSTOMER_SUCCESS:
      return {
        ...state,
        status: "success",
        message: "Customer added successfully",
      };
    default:
      return state;
  }
};

export default customerReducer;
