import React from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import {
  Divider,
  FormControl,
  InputLabel,
  FormGroup,
  Select,
  MenuItem,
  Grid,
  TextField,
  Button,
  colors,
  Container,
  Card,
  CardHeader,
  makeStyles,
  CardContent,
  Typography,
  Chip,
  OutlinedInput,
  Box,
} from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import SecurityIcon from "@material-ui/icons/Security";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { addHome } from "src/actions/HomeActions";
import PlacesAutocomplete from "react-places-autocomplete";
import { AMENITIES_LIST, COUNTRIES, RULES_LIST } from "src/utils/misc";

function getStyles(name, amenities, theme) {
  return {
    fontWeight:
      amenities.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const useStyles = makeStyles(() => ({
  root: {
    padding: 10,
    marginTop: 30,
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: colors.grey[300],
  },
  content: {
    marginTop: 10,
  },
  actions: {
    justifyContent: "center",
  },
  input: {
    display: "none",
  },
  avatar: {
    height: 150,
    width: 150,
    borderRadius: 0,
  },
  saveButton: {
    color: "#fff",
    backgroundColor: colors.green[600],
    "&:hover": {
      backgroundColor: colors.green[900],
    },
    width: "30%",
  },
}));

function CreateHome({ addHome }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();

  const [formState, setFormState] = React.useState({
    homeTitle: "",
    homeType: "Entire House",
    homeRules: "",
    homeDescription: "",
    homePrice: "",
    homeAmenities: "",
    whatGuestsWillHave: "Entire House",
    numberOfGuestsExpected: "",
    numberOfBedrooms: "",
    numberOfBeds: "",
    numberOfBathrooms: "",
    spaceToUse: "",
    otherDetails: "",
    homeAddress: "",
    checkInOption: "",
    homeCountry: "",
    monthlyDiscount: 0,
    weeklyDiscount: 0,
    // minHomePrice: "",
    // maxHomePrice: "",
    minNights: 1,
    maxNights: 356,
  });
  const [location, setLocation] = React.useState("");
  const [coHostState, setCoHostState] = React.useState({
    co_hostNames: "",
    co_hostEmail: "",
    co_hostPhoneNumber: "",
  });
  const [amenities, setAmenities] = React.useState([]);
  const [country, setCountry] = React.useState("Uganda");
  const [rules, setRules] = React.useState([]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChangeRules = (event) => {
    const {
      target: { value },
    } = event;
    setRules(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeAmenities = (event) => {
    const {
      target: { value },
    } = event;
    setAmenities(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <Container className={classes.root}>
      <Card>
        <CardHeader subheader="Add Home Details" title="Home" />
        <Divider className={classes.divider} />

        <CardContent style={{ marginTop: 20 }}>
          <Formik
            initialValues={formState}
            validationSchema={Yup.object().shape({
              homeTitle: Yup.string().required("Please enter the Home Title"),
              // homeRules: Yup.string().required("Please enter the Home Rules"),
              homeDescription: Yup.string().required(
                "Please enter the Home Description"
              ),
              // homeLocation: Yup.string().required(
              //   "Please enter the Home Location"
              // ),
              homePrice: Yup.number().required(
                "Please enter the Home Price (numbers only)"
              ),
              minHomePrice: Yup.number("Minimum home price must be number"),
              maxHomePrice: Yup.number("Maximum home price must be number"),
              minNights: Yup.number("Minimum nights must be number"),
              maxNights: Yup.number("Maximum nights must be number"),
              monthlyDiscount: Yup.number("Monthly discount must be number"),
              weeklyDiscount: Yup.number("Weekly discount must be number"),
              numberOfGuestsExpected: Yup.number().required(
                "Please enter the Number of guests expected(Numbers only)"
              ),
              numberOfBedrooms: Yup.string().required(
                "Please enter the Number of bedrooms (Numbers only)"
              ),
              numberOfBeds: Yup.string().required(
                "Please enter the Number of beds (Numbers only)"
              ),
              numberOfBathrooms: Yup.string().required(
                "Please enter the Number of Bathrooms (Numbers only)"
              ),
              spaceToUse: Yup.string().required(
                "Please enter the Space to use"
              ),
              otherDetails: Yup.string().required(
                "Please enter the Other details"
              ),
              homeAddress: Yup.string().required(
                "Please enter the Home Address"
              ),
              checkInOption: Yup.string().required(
                "Please enter the Checkin Option"
              ),
              // homeCountry: Yup.string().required(
              //   "Please enter the Home Country"
              // ),
            })}
            onSubmit={async (values, { resetForm }) => {
              const obj = {
                ...values,
                whatGuestsWillHave: formState.whatGuestsWillHave,
                homeType: formState.homeType,
                homeLocation: location,
                co_host: coHostState,
                availableDates: [],
                homeAmenities: amenities,
                homeCountry: country,
                homeRules: rules,
                // minHomePrice: formState.homePrice,
                // maxHomePrice: formState.homePrice,
              };
              await addHome(obj, navigate);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <Form className={classes.form} noValidate onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl
                      style={{ minWidth: "100%" }}
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        What kind of place are you listing?
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        onChange={(e) =>
                          setFormState({
                            ...formState,
                            homeType: e.target.value,
                          })
                        }
                        label="What kind of place are you listing?"
                        autoWidth
                      >
                        {[
                          "Secondary Unit",
                          "Entire House",
                          "Bed and Breakfast",
                          "Boutique",
                          "Apartment",
                          "Hotel Room",
                        ].map((item) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormGroup>
                      <Autocomplete
                        id="country-select-demo"
                        disableClearable
                        fullWidth
                        options={COUNTRIES}
                        onChange={(event, newValue) => {
                          setCountry(newValue);
                        }}
                        inputValue={country}
                        onInputChange={(event, newInputValue) => {
                          setCountry(newInputValue);
                        }}
                        autoHighlight
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Which Country is your home?"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup>
                      <PlacesAutocomplete
                        value={location}
                        onChange={setLocation}
                        onSelect={setLocation}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div>
                            <TextField
                              {...getInputProps({
                                variant: "outlined",
                                placeholder: "Add home location",
                                className: "form-control m-2",
                                style: { height: "50px", width: "100%" },
                              })}
                            />
                            <div>
                              {loading ? (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: 10,
                                  }}
                                >
                                  <div className="loader" />
                                </div>
                              ) : null}
                              {suggestions.map((suggestion) => {
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      style: {
                                        backgroundColor: suggestion.active
                                          ? "#ececec"
                                          : "#fff",
                                        padding: 5,
                                        marginTop: 10,
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                      },
                                    })}
                                  >
                                    <LocationCityIcon
                                      style={{ marginRight: 10 }}
                                    />
                                    <Typography variant="inherit">
                                      {suggestion.description}
                                    </Typography>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl
                      style={{ minWidth: "100%" }}
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        What will guest have?
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        onChange={(e) =>
                          setFormState({
                            ...formState,
                            whatGuestsWillHave: e.target.value,
                          })
                        }
                        label="What will guest have?"
                        autoWidth
                      >
                        {[
                          "Entire House",
                          "Private Room",
                          "Shared Room",
                          "All Apartment",
                        ].map((item) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormGroup>
                      <Field
                        as={TextField}
                        error={Boolean(
                          touched.numberOfGuestsExpected &&
                            errors.numberOfGuestsExpected
                        )}
                        helperText={
                          touched.numberOfGuestsExpected &&
                          errors.numberOfGuestsExpected
                        }
                        variant="outlined"
                        required
                        fullWidth
                        id="numberOfGuestsExpected"
                        label="How many guests can your space accommodate?"
                        name="numberOfGuestsExpected"
                        type="number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormGroup>
                      <Field
                        as={TextField}
                        error={Boolean(
                          touched.numberOfBedrooms && errors.numberOfBedrooms
                        )}
                        helperText={
                          touched.numberOfBedrooms && errors.numberOfBedrooms
                        }
                        variant="outlined"
                        required
                        fullWidth
                        id="numberOfBedrooms"
                        label="How many total bedrooms can your guests use?"
                        name="numberOfBedrooms"
                        type="number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormGroup>
                      <Field
                        as={TextField}
                        error={Boolean(
                          touched.numberOfBeds && errors.numberOfBeds
                        )}
                        helperText={touched.numberOfBeds && errors.numberOfBeds}
                        variant="outlined"
                        required
                        fullWidth
                        id="numberOfBeds"
                        label="How many beds can guests use?"
                        name="numberOfBeds"
                        type="number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormGroup>
                      <Field
                        as={TextField}
                        error={Boolean(
                          touched.numberOfBathrooms && errors.numberOfBathrooms
                        )}
                        helperText={
                          touched.numberOfBathrooms && errors.numberOfBathrooms
                        }
                        variant="outlined"
                        required
                        fullWidth
                        id="numberOfBathrooms"
                        label="How many bathrooms?"
                        name="numberOfBathrooms"
                        type="number"
                        min={0}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <FormGroup>
                      <Field
                        as={TextField}
                        error={Boolean(
                          touched.checkInOption && errors.checkInOption
                        )}
                        helperText={
                          touched.checkInOption && errors.checkInOption
                        }
                        variant="outlined"
                        required
                        fullWidth
                        id="checkInOption"
                        label="When can guests check in?"
                        name="checkInOption"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="(We advise you make it flexible or you can put a time) Ex. Flexible"
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <FormGroup>
                      <Field
                        as={TextField}
                        error={Boolean(
                          touched.homeAddress && errors.homeAddress
                        )}
                        helperText={touched.homeAddress && errors.homeAddress}
                        variant="outlined"
                        required
                        fullWidth
                        id="homeAddress"
                        label="Where’s your place located? Exact address!"
                        name="homeAddress"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Guests will only get your exact address once they’ve booked a reservation. (City, Street name, Plot No. Apartment Building) Example: Naalya, Naalya Estate, Spine Road, Casa Fortuna Apartment"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup>
                      <FormControl
                        style={{ minWidth: "100%" }}
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel id="demo-multiple-chip-label">
                          What amenities do you offer?
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-chip-label"
                          id="demo-multiple-chip"
                          multiple
                          value={amenities}
                          onChange={handleChangeAmenities}
                          input={
                            <OutlinedInput
                              id="select-multiple-chip"
                              label="What amenities do you offer?"
                            />
                          }
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip key={value} label={value} />
                              ))}
                            </Box>
                          )}
                          MenuProps={MenuProps}
                        >
                          {AMENITIES_LIST.map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              style={getStyles(name, amenities, theme)}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup>
                      <Field
                        as={TextField}
                        error={Boolean(touched.spaceToUse && errors.spaceToUse)}
                        helperText={touched.spaceToUse && errors.spaceToUse}
                        variant="outlined"
                        required
                        fullWidth
                        id="spaceToUse"
                        label="What spaces can guests use?"
                        name="spaceToUse"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Include common areas (Example: Kitchen, laundry, pool)"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup>
                      <Field
                        as={TextField}
                        error={Boolean(
                          touched.homeDescription && errors.homeDescription
                        )}
                        helperText={
                          touched.homeDescription && errors.homeDescription
                        }
                        variant="outlined"
                        required
                        fullWidth
                        id="homeDescription"
                        label="Describe your place to guests"
                        name="homeDescription"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Write a quick summary of your place. You can highlight what’s special about your space, the neighborhood, and how you’ll interact with guests."
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup>
                      <Field
                        as={TextField}
                        error={Boolean(touched.homeTitle && errors.homeTitle)}
                        helperText={touched.homeTitle && errors.homeTitle}
                        variant="outlined"
                        required
                        fullWidth
                        id="homeTitle"
                        label="Create a title for your listing"
                        name="homeTitle"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Catch guests’ attention with a listing title that highlights what makes your place special."
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup>
                      <FormControl
                        style={{ minWidth: "100%" }}
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel id="demo-multiple-chip-label">
                          Set house rules for your guests
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-chip-label"
                          id="demo-multiple-chip"
                          multiple
                          value={rules}
                          onChange={handleChangeRules}
                          input={
                            <OutlinedInput
                              id="select-multiple-chip"
                              label="Set house rules for your guests"
                            />
                          }
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip key={value} label={value} />
                              ))}
                            </Box>
                          )}
                          MenuProps={MenuProps}
                        >
                          {RULES_LIST.map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              style={getStyles(name, rules, theme)}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup>
                      <Field
                        as={TextField}
                        error={Boolean(
                          touched.otherDetails && errors.otherDetails
                        )}
                        helperText={touched.otherDetails && errors.otherDetails}
                        variant="outlined"
                        required
                        fullWidth
                        id="otherDetails"
                        label="Other details guests must know about your home"
                        name="otherDetails"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup>
                      <Field
                        as={TextField}
                        error={Boolean(touched.homePrice && errors.homePrice)}
                        helperText={touched.homePrice && errors.homePrice}
                        variant="outlined"
                        required
                        fullWidth
                        id="homePrice"
                        label="Set base price for Your Space. (USD)"
                        name="homePrice"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Set a good price to increase your chances of getting booked."
                        value={values.homePrice}
                      />
                    </FormGroup>
                  </Grid>
                  <Typography
                    variant="h6"
                    style={{
                      marginLeft: 10,
                      marginTop: 20,
                      color: "#0d8487",
                      width: "100%",
                    }}
                  >
                    How long can guests stay? Optional
                  </Typography>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormGroup>
                      <Field
                        as={TextField}
                        error={Boolean(touched.minNights && errors.minNights)}
                        helperText={touched.minNights && errors.minNights}
                        variant="outlined"
                        fullWidth
                        id="minNights"
                        label="Minimum Nights"
                        name="minNights"
                        type="number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Shorter trips can mean more reservations, but you will turn over your space more often."
                        value={values.minNights}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormGroup>
                      <Field
                        as={TextField}
                        error={Boolean(touched.maxNights && errors.maxNights)}
                        helperText={touched.maxNights && errors.maxNights}
                        variant="outlined"
                        fullWidth
                        id="maxNights"
                        label="Maximum Nights"
                        name="maxNights"
                        type="number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Shorter trips can mean more reservations, but you will turn over your space more often."
                        value={values.maxNights}
                      />
                    </FormGroup>
                  </Grid>
                  <Typography
                    variant="h6"
                    style={{
                      marginLeft: 10,
                      marginTop: 20,
                      color: "#0d8487",
                      width: "100%",
                    }}
                  >
                    Length of stay discounts. Optional
                  </Typography>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormGroup>
                      <Field
                        as={TextField}
                        error={Boolean(
                          touched.weeklyDiscount && errors.weeklyDiscount
                        )}
                        helperText={
                          touched.weeklyDiscount && errors.weeklyDiscount
                        }
                        variant="outlined"
                        fullWidth
                        id="weeklyDiscount"
                        label="Weekly discount (%)"
                        name="weeklyDiscount"
                        type="number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="To encourage longer stays, some hosts set a weekly or monthly discount"
                        value={values.weeklyDiscount}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormGroup>
                      <Field
                        as={TextField}
                        error={Boolean(
                          touched.monthlyDiscount && errors.monthlyDiscount
                        )}
                        helperText={
                          touched.monthlyDiscount && errors.monthlyDiscount
                        }
                        variant="outlined"
                        fullWidth
                        id="monthlyDiscount"
                        label="Monthly discount (%)"
                        name="monthlyDiscount"
                        type="number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="To encourage longer stays, some hosts set a weekly or monthly discount"
                        value={values.monthlyDiscount}
                      />
                    </FormGroup>
                  </Grid>
                  <Typography
                    variant="h6"
                    style={{ marginLeft: 10, marginTop: 20, color: "#0d8487" }}
                  >
                    Add Co-Host Information. (This could be the care taker or
                    Someone managing the home) Optional
                  </Typography>
                  <Grid item xs={12}>
                    <FormGroup>
                      <Field
                        as={TextField}
                        variant="outlined"
                        fullWidth
                        id="co_hostNames"
                        label="Add Co-Host Full Names (If the you have one)"
                        name="co_hostNames"
                        type="text"
                        onChange={(e) =>
                          setCoHostState({
                            ...coHostState,
                            co_hostNames: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup>
                      <Field
                        as={TextField}
                        variant="outlined"
                        fullWidth
                        id="co_hostEmail"
                        label="Add Co-Host Email Address"
                        name="co_hostEmail"
                        type="text"
                        onChange={(e) =>
                          setCoHostState({
                            ...coHostState,
                            co_hostEmail: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup>
                      <Field
                        as={TextField}
                        variant="outlined"
                        fullWidth
                        id="co_hostPhoneNumber"
                        label="Add Co-Host Phone Number"
                        name="co_hostPhoneNumber"
                        type="text"
                        onChange={(e) =>
                          setCoHostState({
                            ...coHostState,
                            co_hostPhoneNumber: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}></Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  startIcon={<SecurityIcon />}
                >
                  Continue to add images
                </Button>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Container>
  );
}

export default connect(null, { addHome })(CreateHome);
