import React from "react";
import { Helmet } from "react-helmet";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Box,
  Container,
  makeStyles,
  TextField,
  Button,
  Typography,
} from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/ArrowDropDownCircleRounded";
import { CSVLink } from "react-csv";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";
import { connect } from "react-redux";
import { eventBookingFetchAsync } from "src/actions/event.action";

const useStyles = makeStyles(() => ({
  root: {
    minHeight: "100%",
    marginTop: 20,
    paddingBottom: 10,
    paddingTop: 10,
    paddingLeft: 10,
  },
  cursorPointer: {
    cursor: "pointer",
  },
  viewBtns: {
    textAlign: "center",
    color: "#fff",
    fontWeight: "500",
  },
}));

function ViewEventBookingList({ eventBookingFetchAsync, eventBookings }) {
  const classes = useStyles();

  const [search, setSearch] = React.useState("");
  // const [filteredItems, setFilteredItems] = React.useState([]);

  React.useEffect(() => {
    eventBookingFetchAsync();
  }, [eventBookingFetchAsync]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  let filteredItems =
    eventBookings &&
    eventBookings.filter((booking) => {
      return `${booking.eventPaidFor}`.toLowerCase().indexOf(search) !== -1;
    });

  const getTotalEarnings = () => {
    return filteredItems.reduce(
      (total, val) =>
        total + parseFloat(val.amountPaid - val.amountPaid * 0.11),
      0
    );
  };

  return (
    <Container className={classes.root} title="Users list">
      <Helmet>
        <title>Tubayo Host | Event Bookings</title>
      </Helmet>
      <Box>
        <Box
          sx={{
            width: "50%",
            marginBottom: 20,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TextField
            variant="standard"
            fullWidth
            label="Search by event name"
            type="text"
            onChange={(e) => handleSearchChange(e)}
            placeholder="Search by event name"
          />
        </Box>
        <Card>
          <Box sx={{ margin: 10 }}>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<DownloadIcon fontSize="18" />}
            >
              <CSVLink
                data={filteredItems}
                style={{
                  textDecoration: "none",
                  color: "#999",
                }}
              >
                Download Bookings
              </CSVLink>
            </Button>
            <Typography variant="h4" style={{ marginTop: 10 }}>
              Total Earnings for {search.length > 0 ? search : "All"}:
              {` UGX ${getTotalEarnings()}`}
            </Typography>
          </Box>
          <PerfectScrollbar>
            <Box minWidth={600}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Event</TableCell>
                    <TableCell>Guest Names</TableCell>
                    <TableCell>Contact</TableCell>
                    <TableCell>Ticket Paid</TableCell>
                    <TableCell>Ticket Price</TableCell>
                    <TableCell>Amount Paid</TableCell>
                    <TableCell>Number of Tickets</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredItems &&
                    filteredItems.slice(0, 10).map((booking, index) => (
                      <TableRow
                        hover
                        key={index}
                        className={classes.cursorPointer}
                      >
                        <TableCell>
                          {moment(booking.bookingDate).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell>{`${booking.eventPaidFor}`}</TableCell>
                        <TableCell>{`${booking.firstName} ${booking.lastName}`}</TableCell>
                        <TableCell>{booking.phoneNumber}</TableCell>
                        <TableCell>{booking.ticket}</TableCell>
                        <TableCell align="center">
                          {booking.ticketPrice}
                        </TableCell>
                        <TableCell align="center">
                          {booking.amountPaid}
                        </TableCell>
                        <TableCell align="center">
                          {booking.numberOfTickets}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={filteredItems.length}
            page={1}
            rowsPerPage={10}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Card>
      </Box>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  eventBookings: state.eventReducer.eventBookings,
});

export default connect(mapStateToProps, { eventBookingFetchAsync })(
  ViewEventBookingList
);
