import { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  colors,
  TableRow,
  Chip,
} from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";
import { APIURL } from "src/utils/misc";
import { getHomeById } from "src/actions/HomeActions";
import { connect } from "react-redux";

const HomeListResults = ({ homes, getHomeById, home, ...rest }) => {
  const navigate = useNavigate();

  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(0);
  const [copied, setCopied] = useState(false);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    console.log(event);
    setPage(newPage);
  };

  const onDelete = async (homeId) => {
    try {
      await axios.delete(`${APIURL}homes/${homeId}`);
      window.location.reload();
    } catch (error) {
      toast.error("Action failed!");
    }
  };

  const viewHome = async (homeId) => {
    await getHomeById(homeId, navigate);
  };

  const viewCalendar = async (homeId) => {
    await getHomeById(homeId);
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                {/* <TableCell>No.</TableCell> */}
                <TableCell>Actions</TableCell>
                <TableCell>Calendar</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Price (USD)</TableCell>
                <TableCell>Checkin Option</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {homes.slice(0, limit).map((home, idx) => (
                <TableRow hover key={home.id}>
                  {/* <TableCell>{++idx}</TableCell> */}
                  <TableCell>
                    <Chip
                      label="Edit / View"
                      clickable
                      style={{
                        color: colors.orange[600],
                        borderColor: colors.orange[600],
                        marginRight: 5,
                      }}
                      onClick={() => viewHome(home._id)}
                      variant="outlined"
                    />
                    {home.approved ? (
                      <CopyToClipboard
                        text={`https://tubayo.com/homes/${home._id}`}
                        onCopy={() => {
                          setCopied(!copied);
                          toast.success("Copied!!");
                          setCopied(false);
                        }}
                      >
                        <Chip
                          label="Copy Home URL"
                          clickable
                          style={{
                            color: "#0d8487",
                            borderColor: "#0d8487",
                            marginRight: 5,
                          }}
                          variant="outlined"
                        />
                      </CopyToClipboard>
                    ) : null}
                    <Chip
                      label="Delete"
                      clickable
                      style={{
                        color: colors.red[600],
                        borderColor: colors.red[600],
                        marginRight: 5,
                      }}
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure you wish to delete this Home?"
                          )
                        )
                          onDelete(home._id);
                      }}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>
                    <Chip
                      label="Manage Calendar"
                      clickable
                      style={{
                        color: colors.blue[600],
                        borderColor: colors.blue[600],
                        marginRight: 5,
                      }}
                      onClick={() => viewCalendar(home._id)}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>{home.homeTitle}</TableCell>
                  <TableCell>{home.homeType}</TableCell>
                  <TableCell>{home.homeLocation}</TableCell>
                  <TableCell>{home.homePrice}</TableCell>
                  <TableCell>{home.checkInOption}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={homes.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
      {/* <ModalCalendar
        open={openCalendarModal}
        home={home}
        closeModal={() => setOpenCalendarModal(false)}
      /> */}
    </Card>
  );
};

HomeListResults.propTypes = {
  homes: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  home: state.homes.home,
});

export default connect(mapStateToProps, { getHomeById })(HomeListResults);
