import axios from "axios";
import { toast } from "react-toastify";
import { APIURL } from "../utils/misc";
import { EXPERIENCE_TYPE } from "./types";

export function setExperience(experience) {
  return {
    type: EXPERIENCE_TYPE.SET_EXPERIENCE,
    experience,
  };
}

export function fetchExperiences() {
  const host = localStorage.getItem("HOST_ID");
  return async (dispatch) => {
    try {
      const res = await axios.get(`${APIURL}hosts/${host}/experience`);
      const { data } = res.data;
      dispatch(setExperience(data));
    } catch (error) {
      toast.error("error.message");
    }
  };
}

export const addExperience = (data, navigate) => {
  const host = localStorage.getItem("HOST_ID");
  return async (dispatch) => {
    let res;
    try {
      res = await axios.post(`${APIURL}hosts/${host}/experience`, data);
      if (res.data.message === "Experience with name already exist!") {
        toast.error("Experience with name already exist!");
      } else {
        dispatch({
          type: EXPERIENCE_TYPE.ADD_EXPERIENCE,
          payload: res.data,
        });
        toast.success("Experience was added successfully!");
        navigate("/app/experience/uploadImages", { replace: true });
      }
    } catch (error) {
      if (error.message === "Network Error") {
        toast.error("Check your network connection");
      } else {
        toast.error(
          "We are working on it, please try again while we implement"
        );
      }
    }
  };
};

export const getExperienceById = (data, navigate) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(`${APIURL}experiences/${data}`);
      dispatch({
        type: EXPERIENCE_TYPE.GET_EXPERIENCE,
        payload: res.data,
      });
      navigate("/app/experience/edit");
    } catch (error) {
      toast.error("Faile to fetch experience");
    }
  };
};

export const editExperience = (data, experienceId, navigate) => {
  return async (dispatch) => {
    try {
      let headerType;
      if (!data.imageCover) {
        headerType = {
          "Content-Type": "application/json",
        };
      } else {
        headerType = {
          "Content-Type": "multipart/form-data",
        };
      }
      const res = await axios.patch(
        `${APIURL}experiences/${experienceId}`,
        data,
        {
          headers: headerType,
        }
      );
      dispatch({
        type: EXPERIENCE_TYPE.GET_EXPERIENCE,
        payload: res.data.experience,
      });
      toast.success("Experience was uploaded successfully!");
      navigate("/app/experiences", { replace: true });
    } catch (error) {
      toast.error("Please check your network connections");
    }
  };
};

export const uploadExperienceCoverImage = (data) => async () => {
  try {
    const res = await axios.post(`${APIURL}experiences/uploadCover`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return res.data;
  } catch (error) {
    toast.error("File upload failed!");
  }
};

export const deleteImageFile = (data) => async () => {
  try {
    const res = await axios.post(`${APIURL}experiences/deleteImage`, data);
    return res.data;
  } catch (error) {
    toast.error("Action  failed!");
  }
};
