import React from "react";
import { Helmet } from "react-helmet";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Box,
  Button,
  Container,
  makeStyles,
} from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/ArrowDropDownCircleRounded";
import { CSVLink } from "react-csv";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";
import { connect } from "react-redux";
import { fetctHostProfile } from "src/actions/EditHost";
import { useNavigate } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    marginTop: 20,
    paddingBottom: 10,
    paddingTop: 10,
    paddingLeft: 10,
  },
  cursorPointer: {
    cursor: "pointer",
  },
  viewBtns: {
    textAlign: "center",
    color: "#fff",
    fontWeight: "500",
  },
}));

function ViewHomeBookingList({ fetctHostProfile, home_bookings }) {
  const classes = useStyles();
  const navigate = useNavigate();

  React.useEffect(() => {
    fetctHostProfile(navigate);
    // eslint-disable-next-line
  }, [fetctHostProfile]);

  return (
    <Container className={classes.root} title="Users list">
      <Helmet>
        <title>Tubayo Host | Home Bookings</title>
      </Helmet>
      <Box>
        <Card>
          <Box sx={{ margin: 10 }}>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<DownloadIcon fontSize="18" />}
            >
              <CSVLink
                data={home_bookings}
                style={{
                  textDecoration: "none",
                  color: "#999",
                }}
              >
                Download Bookings
              </CSVLink>
            </Button>
          </Box>
          <PerfectScrollbar>
            <Box minWidth={600}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Home</TableCell>
                    <TableCell>Guest Names</TableCell>
                    <TableCell>Contact</TableCell>
                    <TableCell>Checkin Date</TableCell>
                    <TableCell>Nights</TableCell>
                    <TableCell>No. of Guests</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {home_bookings &&
                    home_bookings.slice(0, 10).map((home, index) => (
                      <TableRow
                        hover
                        key={index}
                        className={classes.cursorPointer}
                      >
                        <TableCell>
                          {moment(home.bookingDate).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell>{`${home.homePaidFor}`}</TableCell>
                        <TableCell>{`${home.firstName} ${home.lastName}`}</TableCell>
                        <TableCell>{home.phoneNumber}</TableCell>
                        <TableCell>
                          {moment(home.checkinDate).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell>{home.nightsToStay}</TableCell>
                        <TableCell>{home.numberOfGuests}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={home_bookings.length}
            page={1}
            rowsPerPage={10}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Card>
      </Box>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  home_bookings: state.edit_host.homeBooking,
});

export default connect(mapStateToProps, { fetctHostProfile })(
  ViewHomeBookingList
);
