import { EXPERIENCE_TYPE } from "../../actions/types";

const initState = {
  successMessage: "",
  experience: null,
  errorMessage: "",
};

const addExperienceReducer = (state = initState, action) => {
  switch (action.type) {
    case EXPERIENCE_TYPE.ADD_EXPERIENCE:
      return {
        ...state,
        status: action.payload.status,
        experience: action.payload.experience,
      };
    case EXPERIENCE_TYPE.EXPERIENCE_ERROR:
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
};

export default addExperienceReducer;
