import React from "react";
import { useNavigate } from "react-router-dom";
import PlacesAutocomplete from "react-places-autocomplete";
import {
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  CardHeader,
  TextField,
  Container,
  Button,
  FormGroup,
  Card,
  CardContent,
  makeStyles,
  colors,
  Avatar,
  Typography,
  Chip,
  OutlinedInput,
  Box,
} from "@material-ui/core";
import { useTheme } from "@mui/material/styles";
import SecurityIcon from "@material-ui/icons/Security";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { SingleDatePicker } from "react-dates";
import moment from "moment";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { editHome } from "src/actions/HomeActions";
import { AMENITIES_LIST, RULES_LIST } from "src/utils/misc";

function getStyles(name, amenities, theme) {
  return {
    fontWeight:
      amenities.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const useStyles = makeStyles(() => ({
  root: {
    padding: 3,
    marginTop: 30,
  },
  divider: {
    marginTop: 10,
    backgroundColor: colors.grey[300],
    marginBottom: 10,
  },
  avata: {
    width: 300,
    height: 350,
    borderRadius: 0,
  },
  content: {
    marginTop: 20,
  },
  actions: {
    justifyContent: "center",
  },
  input: {
    display: "none",
  },
  saveButton: {
    color: "#fff",
    backgroundColor: colors.green[600],
    "&:hover": {
      backgroundColor: colors.green[900],
    },
    width: "30%",
  },
}));

function EditHome({ editHome, home }) {
  const theme = useTheme();
  const [formState, setFormState] = React.useState({
    homeTitle: "",
    homeType: "Entire House",
    homeRules: "",
    homeDescription: "",
    homePrice: "",
    homeAmenities: "",
    whatGuestsWillHave: "Entire House",
    numberOfGuestsExpected: "",
    numberOfBedrooms: "",
    numberOfBeds: "",
    numberOfBathrooms: "",
    spaceToUse: "",
    otherDetails: "",
    homeAddress: "",
    checkInOption: "",
    homeCountry: "",
    monthlyDiscount: "",
    weeklyDiscount: "",
    minHomePrice: "",
    maxHomePrice: "",
    minNights: "",
    maxNights: "",
    dates: [],
    bookedDates: [],
    calendarFocused: false,
  });
  const [location, setLocation] = React.useState(`${home.homeLocation}`);
  const [coHostState, setCoHostState] = React.useState({
    co_hostNames: "",
    co_hostEmail: "",
    co_hostPhoneNumber: "",
  });

  React.useEffect(() => {
    setFormState({
      ...formState,
      ...home,
      dates:
        (home.bookedDates &&
          home.bookedDates.map((d) => moment(d).format("YYYY-MM-DD"))) ||
        [],
      bookedDates: home.bookedDates || [],
    });
    setAmenities(home.homeAmenities);
    setRules(home.homeRules);
    setCoHostState({
      ...coHostState,
      ...home.co_host,
    });
    // eslint-disable-next-line
  }, [home]);
  const handleDateChange = (D) => {
    const date = moment(D).format("YYYY-MM-DD");
    const isPresent = formState.dates.find((d) => d === date);
    setFormState({
      ...formState,
      dates: formState.dates
        .filter((d) => (isPresent ? date !== d : true))
        .concat(isPresent ? [] : [date]),
      bookedDates: formState.dates
        .filter((d) => (isPresent ? date !== d : true))
        .concat(isPresent ? [] : [date]),
    });
  };

  const navigate = useNavigate();
  const classes = useStyles();

  const [amenities, setAmenities] = React.useState([]);
  const [rules, setRules] = React.useState([]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChangeRules = (event) => {
    const {
      target: { value },
    } = event;
    setRules(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeAmenities = (event) => {
    const {
      target: { value },
    } = event;
    setAmenities(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <Container className={classes.root}>
      <Card style={{ marginTop: 20 }}>
        <CardHeader subheader="View or Edit Home" title="Home" />
        <Divider className={classes.divider} />
        <CardContent>
          <Formik
            initialValues={home}
            validationSchema={Yup.object().shape({
              homeTitle: Yup.string().required("Please enter the Home Title"),
              homeDescription: Yup.string().required(
                "Please enter the Home Description"
              ),
              homePrice: Yup.number().required(
                "Please enter the Home Price (numbers only)"
              ),
              minHomePrice: Yup.number("Minimum home price must be number"),
              maxHomePrice: Yup.number("Maximum home price must be number"),
              minNights: Yup.number("Minimum nights must be number"),
              maxNights: Yup.number("Maximum nights must be number"),
              monthlyDiscount: Yup.number("Monthly discount must be number"),
              weeklyDiscount: Yup.number("Weekly discount must be number"),
              numberOfGuestsExpected: Yup.number().required(
                "Please enter the Number of guests expected(Numbers only)"
              ),
              numberOfBedrooms: Yup.string().required(
                "Please enter the Number of bedrooms (Numbers only)"
              ),
              numberOfBeds: Yup.string().required(
                "Please enter the Number of beds (Numbers only)"
              ),
              numberOfBathrooms: Yup.string().required(
                "Please enter the Number of Bathrooms (Numbers only)"
              ),
              spaceToUse: Yup.string().required(
                "Please enter the Space to use"
              ),
              otherDetails: Yup.string().required(
                "Please enter the Other details"
              ),
              homeAddress: Yup.string().required(
                "Please enter the Home Address"
              ),
              checkInOption: Yup.string().required(
                "Please enter the Checkin Option"
              ),
              homeCountry: Yup.string().required(
                "Please enter the Home Country"
              ),
            })}
            onSubmit={async (values, { resetForm }) => {
              const obj = {
                ...values,
                whatGuestsWillHave: formState.whatGuestsWillHave,
                homeType: formState.homeType,
                homeLocation: location,
                bookedDates: formState.bookedDates,
                co_host: coHostState,
                homeAmenities: amenities,
                homeRules: rules,
              };
              await editHome(obj, home._id, navigate);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form className={classes.form} onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl
                      style={{ minWidth: "100%" }}
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        {formState.homeType !== ""
                          ? "What kind of place are you listing?"
                          : home.homeType}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        onChange={(e) =>
                          setFormState({
                            ...formState,
                            homeType: e.target.value,
                          })
                        }
                        label=" What kind of place are you listing?"
                        autoWidth
                        defaultValue={home.homeType}
                      >
                        {[
                          "Secondary Unit",
                          "Entire House",
                          "Bed and Breakfast",
                          "Boutique",
                          "Apartment",
                          "Hotel Room",
                        ].map((item) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormGroup>
                      <Field
                        as={TextField}
                        error={Boolean(
                          touched.homeCountry && errors.homeCountry
                        )}
                        helperText={touched.homeCountry && errors.homeCountry}
                        variant="outlined"
                        required
                        fullWidth
                        id="homeCountry"
                        label="Which Country is your home?"
                        name="homeCountry"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        defaultValue={home.homeCountry}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup>
                      <PlacesAutocomplete
                        value={location}
                        onChange={setLocation}
                        onSelect={setLocation}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div>
                            <TextField
                              {...getInputProps({
                                variant: "outlined",
                                placeholder: "Add home location",
                                className: "form-control m-2",
                                style: { height: "50px", width: "100%" },
                              })}
                            />
                            <div>
                              {loading ? (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: 10,
                                  }}
                                >
                                  <div className="loader" />
                                </div>
                              ) : null}
                              {suggestions.map((suggestion) => {
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      style: {
                                        backgroundColor: suggestion.active
                                          ? "#ececec"
                                          : "#fff",
                                        padding: 5,
                                        marginTop: 10,
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                      },
                                    })}
                                  >
                                    <LocationCityIcon
                                      style={{ marginRight: 10 }}
                                    />
                                    <Typography variant="inherit">
                                      {suggestion.description}
                                    </Typography>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl
                      style={{ minWidth: "100%" }}
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        What will guest have?
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        onChange={(e) =>
                          setFormState({
                            ...formState,
                            whatGuestsWillHave: e.target.value,
                          })
                        }
                        label="What will guest have?"
                        autoWidth
                        defaultValue={home.whatGuestsWillHave}
                      >
                        {[
                          "Entire House",
                          "Private Room",
                          "Shared Room",
                          "All Apartment",
                        ].map((item) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormGroup>
                      <Field
                        as={TextField}
                        error={Boolean(
                          touched.numberOfGuestsExpected &&
                            errors.numberOfGuestsExpected
                        )}
                        helperText={
                          touched.numberOfGuestsExpected &&
                          errors.numberOfGuestsExpected
                        }
                        variant="outlined"
                        required
                        fullWidth
                        id="numberOfGuestsExpected"
                        label="How many guests can your space accommodate?"
                        name="numberOfGuestsExpected"
                        type="number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        defaultValue={home.numberOfGuestsExpected}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormGroup>
                      <Field
                        as={TextField}
                        error={Boolean(
                          touched.numberOfBedrooms && errors.numberOfBedrooms
                        )}
                        helperText={
                          touched.numberOfBedrooms && errors.numberOfBedrooms
                        }
                        variant="outlined"
                        required
                        fullWidth
                        id="numberOfBedrooms"
                        label="How many total bedrooms can your guests use?"
                        name="numberOfBedrooms"
                        type="number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        defaultValue={home.numberOfBedrooms}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormGroup>
                      <Field
                        as={TextField}
                        error={Boolean(
                          touched.numberOfBeds && errors.numberOfBeds
                        )}
                        helperText={touched.numberOfBeds && errors.numberOfBeds}
                        variant="outlined"
                        required
                        fullWidth
                        id="numberOfBeds"
                        label="How many beds can guests use?"
                        name="numberOfBeds"
                        type="number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        defaultValue={home.numberOfBeds}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormGroup>
                      <Field
                        as={TextField}
                        error={Boolean(
                          touched.numberOfBathrooms && errors.numberOfBathrooms
                        )}
                        helperText={
                          touched.numberOfBathrooms && errors.numberOfBathrooms
                        }
                        variant="outlined"
                        required
                        fullWidth
                        id="numberOfBathrooms"
                        label="How many bathrooms?"
                        name="numberOfBathrooms"
                        type="number"
                        min={0}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        defaultValue={home.numberOfBathrooms}
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <FormGroup>
                      <Field
                        as={TextField}
                        error={Boolean(
                          touched.checkInOption && errors.checkInOption
                        )}
                        helperText={
                          touched.checkInOption && errors.checkInOption
                        }
                        variant="outlined"
                        required
                        fullWidth
                        id="checkInOption"
                        label="When can guests check in?"
                        name="checkInOption"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="(We advise you make it flexible or you can put a time) Ex. Flexible"
                        defaultValue={home.checkInOption}
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <FormGroup>
                      <Field
                        as={TextField}
                        error={Boolean(
                          touched.homeAddress && errors.homeAddress
                        )}
                        helperText={touched.homeAddress && errors.homeAddress}
                        variant="outlined"
                        required
                        fullWidth
                        id="homeAddress"
                        label="Where’s your place located? Exact address!"
                        name="homeAddress"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Guests will only get your exact address once they’ve booked a reservation. (City, Street name, Plot No. Apartment Building) Example: Naalya, Naalya Estate, Spine Road, Casa Fortuna Apartment"
                        defaultValue={home.homeAddress}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup>
                      <FormControl
                        style={{ minWidth: "100%" }}
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel id="demo-multiple-chip-label">
                          What amenities do you offer?
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-chip-label"
                          id="demo-multiple-chip"
                          multiple
                          value={amenities}
                          onChange={handleChangeAmenities}
                          input={
                            <OutlinedInput
                              id="select-multiple-chip"
                              label="What amenities do you offer?"
                            />
                          }
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip key={value} label={value} />
                              ))}
                            </Box>
                          )}
                          MenuProps={MenuProps}
                        >
                          {AMENITIES_LIST.map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              style={getStyles(name, amenities, theme)}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup>
                      <Field
                        as={TextField}
                        error={Boolean(touched.spaceToUse && errors.spaceToUse)}
                        helperText={touched.spaceToUse && errors.spaceToUse}
                        variant="outlined"
                        required
                        fullWidth
                        id="spaceToUse"
                        label="What spaces can guests use?"
                        name="spaceToUse"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Include common areas (Example: Kitchen, laundry, pool)"
                        defaultValue={home.spaceToUse}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup>
                      <Field
                        as={TextField}
                        error={Boolean(
                          touched.homeDescription && errors.homeDescription
                        )}
                        helperText={
                          touched.homeDescription && errors.homeDescription
                        }
                        variant="outlined"
                        required
                        fullWidth
                        id="homeDescription"
                        label="Describe your place to guests"
                        name="homeDescription"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Write a quick summary of your place. You can highlight what’s special about your space, the neighborhood, and how you’ll interact with guests."
                        defaultValue={home.homeDescription}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup>
                      <Field
                        as={TextField}
                        error={Boolean(touched.homeTitle && errors.homeTitle)}
                        helperText={touched.homeTitle && errors.homeTitle}
                        variant="outlined"
                        required
                        fullWidth
                        id="homeTitle"
                        label="Create a title for your listing"
                        name="homeTitle"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Catch guests’ attention with a listing title that highlights what makes your place special."
                        defaultValue={home.homeTitle}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup>
                      <FormControl
                        style={{ minWidth: "100%" }}
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel id="demo-multiple-chip-label">
                          Set house rules for your guests
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-chip-label"
                          id="demo-multiple-chip"
                          multiple
                          value={rules}
                          onChange={handleChangeRules}
                          input={
                            <OutlinedInput
                              id="select-multiple-chip"
                              label="Set house rules for your guests"
                            />
                          }
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip key={value} label={value} />
                              ))}
                            </Box>
                          )}
                          MenuProps={MenuProps}
                        >
                          {RULES_LIST.map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              style={getStyles(name, rules, theme)}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup>
                      <Field
                        as={TextField}
                        error={Boolean(
                          touched.otherDetails && errors.otherDetails
                        )}
                        helperText={touched.otherDetails && errors.otherDetails}
                        variant="outlined"
                        required
                        fullWidth
                        id="otherDetails"
                        label="Other details guests must know about your home"
                        name="otherDetails"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        defaultValue={home.otherDetails}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup>
                      <Field
                        as={TextField}
                        error={Boolean(touched.homePrice && errors.homePrice)}
                        helperText={touched.homePrice && errors.homePrice}
                        variant="outlined"
                        required
                        fullWidth
                        id="homePrice"
                        label="Price Your Space. (USD)"
                        name="homePrice"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Set a good price to increase your chances of getting booked."
                        defaultValue={home.Price}
                      />
                    </FormGroup>
                  </Grid>
                  {/* <Typography
                    variant="h6"
                    style={{
                      marginLeft: 10,
                      marginTop: 20,
                      color: "#0d8487",
                      width: "100%",
                    }}
                  >
                    Smart Pricing.
                  </Typography>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormGroup>
                      <Field
                        as={TextField}
                        error={Boolean(
                          touched.minHomePrice && errors.minHomePrice
                        )}
                        helperText={touched.minHomePrice && errors.minHomePrice}
                        variant="outlined"
                        fullWidth
                        id="minHomePrice"
                        label="Minimum price (USD)"
                        name="minHomePrice"
                        type="number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="When demand for your space is low, nightly price will drop to attract more guests to book"
                        defaultValue={home.minHomePrice}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormGroup>
                      <Field
                        as={TextField}
                        error={Boolean(
                          touched.maxHomePrice && errors.maxHomePrice
                        )}
                        helperText={touched.maxHomePrice && errors.maxHomePrice}
                        variant="outlined"
                        fullWidth
                        id="maxHomePrice"
                        label="Maximum price (USD)"
                        name="maxHomePrice"
                        type="number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="If demand is high, e.g. if there is a popular festival in town."
                        defaultValue={home.maxHomePrice}
                      />
                    </FormGroup>
                  </Grid> */}
                  <Typography
                    variant="h6"
                    style={{
                      marginLeft: 10,
                      marginTop: 20,
                      color: "#0d8487",
                      width: "100%",
                    }}
                  >
                    How long can guests stay?
                  </Typography>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormGroup>
                      <Field
                        as={TextField}
                        error={Boolean(touched.minNights && errors.minNights)}
                        helperText={touched.minNights && errors.minNights}
                        variant="outlined"
                        fullWidth
                        id="minNights"
                        label="Minimum Nights"
                        name="minNights"
                        type="number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Shorter trips can mean more reservations, but you will turn over your space more often."
                        defaultValue={home.minNights}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormGroup>
                      <Field
                        as={TextField}
                        error={Boolean(touched.maxNights && errors.maxNights)}
                        helperText={touched.maxNights && errors.maxNights}
                        variant="outlined"
                        fullWidth
                        id="maxNights"
                        label="Maximum Nights"
                        name="maxNights"
                        type="number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Shorter trips can mean more reservations, but you will turn over your space more often."
                        defaultValue={home.maxNights}
                      />
                    </FormGroup>
                  </Grid>
                  <Typography
                    variant="h6"
                    style={{
                      marginLeft: 10,
                      marginTop: 20,
                      color: "#0d8487",
                      width: "100%",
                    }}
                  >
                    Length of stay discounts.
                  </Typography>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormGroup>
                      <Field
                        as={TextField}
                        error={Boolean(
                          touched.weeklyDiscount && errors.weeklyDiscount
                        )}
                        helperText={
                          touched.weeklyDiscount && errors.weeklyDiscount
                        }
                        variant="outlined"
                        fullWidth
                        id="weeklyDiscount"
                        label="Weekly discount (%)"
                        name="weeklyDiscount"
                        type="number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="To encourage longer stays, some hosts set a weekly or monthly discount"
                        defaultValue={home.weeklyDiscount}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormGroup>
                      <Field
                        as={TextField}
                        error={Boolean(
                          touched.monthlyDiscount && errors.monthlyDiscount
                        )}
                        helperText={
                          touched.monthlyDiscount && errors.monthlyDiscount
                        }
                        variant="outlined"
                        fullWidth
                        id="monthlyDiscount"
                        label="Monthly discount (%)"
                        name="monthlyDiscount"
                        type="number"
                        min={0}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="To encourage longer stays, some hosts set a weekly or monthly discount"
                        defaultValue={home.monthlyDiscount}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup>
                      <Field
                        as={TextField}
                        variant="outlined"
                        fullWidth
                        id="co_hostNames"
                        label="Add Co-Host Full Names (If the you have one)"
                        name="co_hostNames"
                        type="text"
                        defaultValue={home.co_host && home.co_host.co_hostNames}
                        onChange={(e) =>
                          setCoHostState({
                            ...coHostState,
                            co_hostNames: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup>
                      <Field
                        as={TextField}
                        variant="outlined"
                        fullWidth
                        id="co_hostEmail"
                        label="Add Co-Host Email Address"
                        name="co_hostEmail"
                        type="text"
                        defaultValue={home.co_host && home.co_host.co_hostEmail}
                        onChange={(e) =>
                          setCoHostState({
                            ...coHostState,
                            co_hostEmail: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup>
                      <Field
                        as={TextField}
                        variant="outlined"
                        fullWidth
                        id="co_hostPhoneNumber"
                        label="Add Co-Host Phone Number"
                        name="co_hostPhoneNumber"
                        type="text"
                        defaultValue={
                          home.co_host && home.co_host.co_hostPhoneNumber
                        }
                        onChange={(e) =>
                          setCoHostState({
                            ...coHostState,
                            co_hostPhoneNumber: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Manage your Calendar. Select/Add dates you're booked or
                      unavailable.
                    </InputLabel>
                    <br />
                    <SingleDatePicker
                      placeholder="Calendar."
                      block={false}
                      numberOfMonths={1}
                      onDateChange={handleDateChange}
                      focused={formState.calendarFocused}
                      onFocusChange={(e) =>
                        setFormState({
                          ...formState,
                          calendarFocused: !formState.calendarFocused,
                        })
                      }
                      keepOpenOnDateSelect={true} // highly important
                      isDayHighlighted={(d1) =>
                        formState.dates.some((d2) => d1.isSame(d2, "day"))
                      }
                    />
                  </Grid>
                  <Grid item xs={12}></Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  startIcon={<SecurityIcon />}
                >
                  Submit
                </Button>
              </form>
            )}
          </Formik>
        </CardContent>
        <Divider className={classes.divider} />
        <CardContent>
          <Button
            type="submit"
            onClick={() => navigate("/app/home/updateHomeImage")}
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Update Images
          </Button>
          <br />
          <br />
          <Avatar
            style={{ width: 150, height: 200, borderRadius: 5 }}
            src={home.homeCoverImage}
          />
          <br />
          <Grid container spacing={6} wrap="wrap">
            {home.homeImages.map((image, idx) => (
              <Grid item md={2} sm={4} xs={6}>
                <Avatar
                  key={idx}
                  style={{ width: 150, height: 200, borderRadius: 5 }}
                  src={image}
                />
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  home: state.homes.home,
});

export default connect(mapStateToProps, { editHome })(EditHome);
