import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Divider,
  Grid,
  TextField,
  Container,
  Button,
  colors,
  Card,
  CardHeader,
  CardContent,
  makeStyles,
  Typography,
  Box,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@material-ui/core";

import SecurityIcon from "@material-ui/icons/Security";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import {
  async_create_event,
  uploadEventCoverImage,
  deleteImageFile,
} from "src/actions/event.action";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import PlacesAutocomplete from "react-places-autocomplete";
import { toast } from "react-toastify";
import { Add, Cancel } from "@material-ui/icons";
import { compressedFile } from "src/utils/misc";

const useStyles = makeStyles(() => ({
  root: {
    padding: 3,
    marginTop: 30,
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: colors.grey[300],
  },
  content: {
    marginTop: 20,
  },
  actions: {
    justifyContent: "center",
  },
  dateInput: {
    borderWidth: 1,
    borderColor: "#dedede",
    borderRadius: 5,
    cursor: "pointer",
  },
  showStartDatePicker: {
    display: "block",
  },
  hideStartDatePicker: {
    display: "none",
  },
  showEndDatePicker: {
    display: "block",
  },
  hideEndDatePicker: {
    display: "none",
  },
  input: {
    display: "none",
  },
  avatar: {
    height: 150,
    width: 150,
    borderRadius: 0,
  },
  saveButton: {
    color: "#fff",
    backgroundColor: colors.green[600],
    "&:hover": {
      backgroundColor: colors.green[900],
    },
    width: "30%",
  },
}));

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginImagePreview
);

function CreateEvent({
  async_create_event,
  uploadEventCoverImage,
  deleteImageFile,
}) {
  const [address, setAddress] = React.useState("");
  const [cover, setCover] = React.useState([]);
  const [cover_file, setCoverFile] = React.useState({});
  const [ticketPriceState, setTicketPriceState] = React.useState([
    {
      category: "",
      price: "",
      priceRate: "",
    },
  ]);
  const navigate = useNavigate();
  const classes = useStyles();

  let handleChangeTicket = (i, e) => {
    let newTicketPriceState = [...ticketPriceState];
    newTicketPriceState[i][e.target.name] = e.target.value;
    setTicketPriceState(newTicketPriceState);
  };

  let addFormFields = () => {
    setTicketPriceState([
      ...ticketPriceState,
      {
        category: "",
        price: "",
        priceRate: "",
      },
    ]);
  };

  let removeFormFields = (i) => {
    let newTicketPriceState = [...ticketPriceState];
    newTicketPriceState.splice(i, 1);
    setTicketPriceState(newTicketPriceState);
  };

  return (
    <Container className={classes.root}>
      <Card>
        <CardHeader subheader="Add Event Details" title="Event" />
        <Divider className={classes.divider} />
        <CardContent style={{ marginTop: 20 }}>
          <Formik
            initialValues={{
              eventName: "",
              summary: "",
              startDate: new Date(),
              startTime: "10:00",
              endDate: new Date(),
              endTime: "10:00",
              fullDesc: "",
              coverImage: "",
              country: "",
              extraInfo: "",
            }}
            validationSchema={Yup.object().shape({
              eventName: Yup.string().required("Field is required"),
              summary: Yup.string().required("Field is required").max(50),
              fullDesc: Yup.string().required("Field is required"),
              startDate: Yup.date().required("Field required!"),
              endDate: Yup.date().required("Field required"),
              startTime: Yup.string().required("Field required"),
              endTime: Yup.string().required(),
              country: Yup.string().required("Field required!"),
              extraInfo: Yup.string().required("Field required"),
            })}
            onSubmit={async (values, { resetForm }) => {
              if (!cover_file.path)
                return toast.warning("Please add event cover image!");
              const obj = {
                ...values,
                coverImage: cover_file.path,
                address,
                tickets: ticketPriceState,
              };
              await async_create_event(obj, navigate);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form className={classes.form} onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.eventName && errors.eventName)}
                      helperText={touched.eventName && errors.eventName}
                      variant="outlined"
                      fullWidth
                      label="Event Name"
                      name="eventName"
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Event Name"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.country && errors.country)}
                      helperText={touched.country && errors.country}
                      variant="outlined"
                      fullWidth
                      label="Country"
                      name="country"
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Country"
                    />
                  </Grid>

                  <Grid item xs={12} md={12} lg={12}>
                    <PlacesAutocomplete
                      value={address}
                      onChange={setAddress}
                      onSelect={setAddress}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <TextField
                            {...getInputProps({
                              variant: "outlined",
                              placeholder: "Add Address",
                              className: "form-control m-2",
                              style: { height: "50px", width: "100%" },
                            })}
                          />
                          <div>
                            {loading ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginTop: 10,
                                }}
                              >
                                <div className="loader" />
                              </div>
                            ) : null}
                            {suggestions.map((suggestion) => {
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    style: {
                                      backgroundColor: suggestion.active
                                        ? "#ececec"
                                        : "#fff",
                                      padding: 5,
                                      marginTop: 10,
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    },
                                  })}
                                >
                                  <LocationCityIcon
                                    style={{ marginRight: 10 }}
                                  />
                                  <Typography variant="inherit">
                                    {suggestion.description}
                                  </Typography>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField
                      error={Boolean(touched.startDate && errors.startDate)}
                      helperText={touched.startDate && errors.startDate}
                      variant="outlined"
                      fullWidth
                      label="Start Date"
                      name="startDate"
                      type="date"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField
                      error={Boolean(touched.startTime && errors.startTime)}
                      helperText={touched.startTime && errors.startTime}
                      variant="outlined"
                      fullWidth
                      label="Start Time"
                      name="startTime"
                      type="time"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField
                      error={Boolean(touched.endDate && errors.endDate)}
                      helperText={touched.endDate && errors.endDate}
                      variant="outlined"
                      fullWidth
                      label="End Date"
                      name="endDate"
                      type="date"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField
                      error={Boolean(touched.endTime && errors.endTime)}
                      helperText={touched.endTime && errors.endTime}
                      variant="outlined"
                      fullWidth
                      label="End Time"
                      name="endTime"
                      type="time"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      error={Boolean(touched.summary && errors.summary)}
                      helperText={touched.summary && errors.summary}
                      variant="outlined"
                      fullWidth
                      label="Enter brief description about the event"
                      name="summary"
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Event summary"
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      error={Boolean(touched.fullDesc && errors.fullDesc)}
                      helperText={touched.fullDesc && errors.fullDesc}
                      variant="outlined"
                      fullWidth
                      label="Event Description"
                      name="fullDesc"
                      type="text"
                      placeholder="Full event description"
                      min={0}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      error={Boolean(touched.extraInfo && errors.extraInfo)}
                      helperText={touched.extraInfo && errors.extraInfo}
                      variant="outlined"
                      fullWidth
                      label="Extra Information"
                      name="extraInfo"
                      type="text"
                      placeholder="Extra information about the event like restrictions"
                      min={0}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5">
                      Set Ticket Price categories
                    </Typography>
                    {ticketPriceState.map((item, idx) => (
                      <Box key={idx} mt={3}>
                        <FormControl
                          style={{ minWidth: "100%" }}
                          variant="outlined"
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Select Ticket Category
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            style={{ marginBottom: 17 }}
                            onChange={(e) => handleChangeTicket(idx, e)}
                            name="category"
                            label="Select Ticket Category"
                            autoWidth
                          >
                            {["Early Bird", "Ordinary", "VIP", "VVIP"].map(
                              (item) => (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                        <TextField
                          style={{ marginBottom: 17 }}
                          variant="outlined"
                          fullWidth
                          label="Price for how many"
                          name="priceRate"
                          type="text"
                          onChange={(e) => handleChangeTicket(idx, e)}
                          placeholder="Per person , Per table"
                        />
                        <TextField
                          variant="outlined"
                          fullWidth
                          id="price"
                          label="Enter Event price? (USD)"
                          name="price"
                          onChange={(e) => handleChangeTicket(idx, e)}
                          placeholder="Event Price"
                          defaultValue={values.price}
                        />
                        {/* </Grid> */}
                      </Box>
                    ))}
                    <Box>
                      {ticketPriceState.length < 4 ? (
                        <Button
                          variant="contained"
                          color="secondary"
                          style={{ marginTop: 5 }}
                          startIcon={<Add />}
                          onClick={addFormFields}
                        >
                          Add Ticket
                        </Button>
                      ) : null}
                      {ticketPriceState.length > 1 ? (
                        <Button
                          variant="contained"
                          color="default"
                          style={{ marginTop: 5, marginLeft: 10 }}
                          startIcon={<Cancel />}
                          onClick={removeFormFields}
                        >
                          Remove Ticket
                        </Button>
                      ) : null}
                    </Box>
                  </Grid>

                  <Grid item xs={12}></Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Typography variant="h5" display="block" gutterBottom>
                      Add Event cover image here (Maximum 2MB).
                    </Typography>
                    <FilePond
                      files={cover}
                      onupdatefiles={setCover}
                      allowMultiple={false}
                      allowImageResize={true}
                      allowRemove={true}
                      acceptedFileTypes={["image/*"]}
                      server={{
                        process: async (
                          fieldName,
                          file,
                          metadata,
                          load,
                          error,
                          progress,
                          abort
                        ) => {
                          try {
                            const image = await compressedFile(file);
                            const formData = new FormData();
                            formData.append("imageCover", image);
                            const res = await uploadEventCoverImage(formData);
                            setCoverFile(res.file_data);
                            load(res.message);
                          } catch (err) {
                            // console.log(error);
                            error(err.message);
                          }
                          return {
                            abort: () => {
                              abort();
                            },
                          };
                        },
                        revert: async (uniqueFileId, load) => {
                          const keyObject = { Key: cover_file.Key };
                          try {
                            const res = await deleteImageFile(keyObject);
                            setCoverFile({});
                            load(res.message);
                          } catch (error) {
                            console.log(error);
                            load();
                          }
                        },
                      }}
                      name="file"
                      labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  startIcon={<SecurityIcon />}
                >
                  Submit
                </Button>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Container>
  );
}

export default connect(null, {
  async_create_event,
  uploadEventCoverImage,
  deleteImageFile,
})(CreateEvent);
