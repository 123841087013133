import { EDIT_HOST, HOST_ERROR, HOST_PROFILE_TYPES } from "../../actions/types";

const initState = {
  successMessage: "",
  errorMessage: "",
  experienceBooking: [],
  homeBooking: [],
  reservations: [],
  host: null,
};

const editHostReducer = (state = initState, action) => {
  switch (action.type) {
    case EDIT_HOST:
      return { ...state, successMessage: action.payload };
    case HOST_ERROR:
      return { ...state, errorMessage: action.payload };
    case HOST_PROFILE_TYPES.HOST_PROFILE_FETCH_SUCCESS:
      return {
        ...state,
        host: action.payload,
        experienceBooking: action.payload.experienceBooking,
        homeBooking: action.payload.homeBooking,
        status: "success",
      };
    case HOST_PROFILE_TYPES.HOST_RESERVATIONS_SUCCESS:
      return {
        ...state,
        reservations: action.payload,
      };
    default:
      return state;
  }
};

export default editHostReducer;
