import { Navigate } from "react-router-dom";
import DashboardLayout from "src/components/DashboardLayout";
import MainLayout from "src/components/MainLayout";
import Account from "src/pages/Account";
import ExperienceList from "src/pages/ExperienceList";
import Dashboard from "src/pages/Dashboard";
import Login from "src/pages/Login";
import NotFound from "src/pages/NotFound";
import CustomersList from "src/pages/CustomersList";
import CreateEvent from "src/pages/CreateEvent";
import CreateExperience from "src/pages/CreateExperience.page";
import CreateHome from "src/pages/CreateHome.page";
import HomeList from "src/pages/HomeList";
import TransactionList from "./pages/Transactions.page";
import CreateCustomer from "src/pages/CreateCustomer";
import HomeBookingsList from "./pages/HomeBookingsList";
import ExperienceBookingsList from "./pages/ExperienceBookingsList";
import UploadExperienceImages from "./pages/uploadExperienceImages";
import EditExperience from "./pages/EditExperience";
import UpdateExperienceImages from "./pages/updateExperienceImages";
import UploadHomeImages from "./pages/UploadHomeImages";
import EditHome from "./pages/EditHome";
import UpdateHomeImages from "./pages/UpdateHomeImages";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import EventsList from "./pages/EventsList";
import EditEvents from "./pages/EditEvents";
import EventBooking from "./pages/EventBooking";
import HomeReservations from "./pages/HomeReservations";
import HomeCalendar from "./pages/HomeCalendar";

const routes = (isLoggedIn) => [
  {
    path: "app",
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login/:user" />,
    children: [
      { path: "account", element: <Account /> },
      { path: "experiences", element: <ExperienceList /> },
      { path: "events", element: <EventsList /> },
      { path: "homes", element: <HomeList /> },
      { path: "homes/calendar", element: <HomeCalendar /> },
      { path: "createExperience", element: <CreateExperience /> },
      { path: "createEvent", element: <CreateEvent /> },
      { path: "createHome", element: <CreateHome /> },
      { path: "dashboard", element: <Dashboard /> },
      { path: "createCustomer", element: <CreateCustomer /> },
      { path: "transactions", element: <TransactionList /> },
      { path: "customers", element: <CustomersList /> },
      { path: "homeBookings", element: <HomeBookingsList /> },
      { path: "eventBookings", element: <EventBooking /> },
      { path: "experienceBookings", element: <ExperienceBookingsList /> },
      { path: "experience/uploadImages", element: <UploadExperienceImages /> },
      { path: "experience/edit", element: <EditExperience /> },
      { path: "event/edit", element: <EditEvents /> },
      { path: "experience/updateImages", element: <UpdateExperienceImages /> },
      { path: "home/edit", element: <EditHome /> },
      { path: "home/addHomeImage", element: <UploadHomeImages /> },
      { path: "home/updateHomeImage", element: <UpdateHomeImages /> },
      { path: "home/reservations", element: <HomeReservations /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "/",
    element: !isLoggedIn ? <MainLayout /> : <Navigate to="/app/dashboard" />,
    children: [
      { path: "login/:user", element: <Login /> },
      { path: "link_account/:user", element: <ForgotPassword /> },
      // { path: "forgot_password", element: <ForgotPassword /> },
      { path: "reset_password/:token", element: <ResetPassword /> },
      { path: "404", element: <NotFound /> },
      { path: "/", element: <Navigate to="login/:user" /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];

export default routes;
