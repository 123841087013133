import { useEffect } from "react";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet";
import { Box, Container, Grid } from "@material-ui/core";
import RowingIcon from "@material-ui/icons/Rowing";
import HomeIcon from "@material-ui/icons/Home";
import EventIcon from "@material-ui/icons/Event";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import SyncAltOutlinedIcon from "@material-ui/icons/SyncAltOutlined";
import Budget from "src/components/dashboard//Budget";
import { LocalConvenienceStoreRounded } from "@material-ui/icons";
import { connect } from "react-redux";
import { fetchExperiences } from "src/actions/ExperienceActions";
import { fetchHomes } from "src/actions/HomeActions";
import { fetch_customers_async } from "src/actions/customer.actions";
import { fetch_transactions_async } from "src/actions/transaction.actions";
// import { fetchLocationAsync } from "src/actions/location.action";
import { fetctHostProfile, homeReservationAsync } from "src/actions/EditHost";
import { async_fetch_events } from "src/actions/event.action";
import HomeCalendar from "./HomeCalendar";

const Dashboard = ({
  fetchExperiences,
  fetchHomes,
  fetch_customers_async,
  fetch_transactions_async,
  experiences,
  homes,
  transactions,
  customers,
  fetctHostProfile,
  // fetchLocationAsync,
  async_fetch_events,
  homeReservationAsync,
  events,
  reservations,
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    fetctHostProfile(navigate);
    fetchExperiences();
    async_fetch_events();
    fetchHomes();
    fetch_customers_async();
    fetch_transactions_async();
    // fetchLocationAsync();
    homeReservationAsync();
    // eslint-disable-next-line
  }, [
    homeReservationAsync,
    fetchExperiences,
    async_fetch_events,
    fetchHomes,
    fetch_customers_async,
    fetch_transactions_async,
    fetctHostProfile,
    // fetchLocationAsync,
  ]);
  const summary = [
    {
      title: "Experiences",
      count: experiences.length || 0,
      icon: <RowingIcon />,
      fillColor: "#1e88e5",
      href: "/app/experiences",
      status: {
        approved: experiences.filter(
          (experience) => experience.approved === true
        ).length,
        pending: experiences.filter(
          (experience) => experience.approved !== true
        ).length,
      },
    },
    {
      title: "Events",
      count: events.length || 0,
      icon: <EventIcon />,
      fillColor: "#f0ad4e",
      href: "/app/events",
      status: {
        approved: events.filter((event) => event.isApproved === true).length,
        pending: events.filter((event) => event.isApproved !== true).length,
      },
    },
    {
      title: "Homes",
      count: homes.length || 0,
      icon: <HomeIcon />,
      fillColor: "#03a9f4",
      href: "/app/homes",
      status: {
        approved: homes.filter((home) => home.approved === true).length,
        pending: homes.filter((home) => home.approved !== true).length,
      },
    },
    {
      title: "Home Reservations",
      count: reservations?.length,
      icon: <LocalConvenienceStoreRounded />,
      fillColor: "#66bb6a",
      href: "/app/home/reservations",
      status: {
        approved: reservations?.filter(
          (reservation) => reservation?.status === "approved"
        ).length,
        pending: reservations.filter(
          (reservation) => reservation?.status === "pending"
        ).length,
      },
    },
    {
      title: "Customers",
      count: customers.length || 0,
      icon: <PeopleOutlineIcon />,
      fillColor: "#66bb6a",
      href: "/app/customers",
      status: null,
    },
    {
      title: "Transactions",
      count: `USD ${Math.abs(transactions).toFixed(2)}`,
      icon: <SyncAltOutlinedIcon />,
      fillColor: "#8d6e63",
      href: "/app/transactions",
      status: null,
    },
  ];

  const renderCards = () =>
    summary.map((item, idx) => (
      <Grid key={idx} item md={6} xs={12}>
        <Budget
          title={item.title}
          count={item.count}
          icon={item.icon}
          fill={item.fillColor}
          href={item.href}
          status={item.status}
        />
      </Grid>
    ));
  return (
    <>
      <Helmet>
        <title>Tubayo Host | Dashboard</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Grid container spacing={3}>
            {renderCards()}
          </Grid>
          <HomeCalendar />
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  experiences: state.experiences.experience,
  homes: state.homes.homes,
  customers: state.customers.customers,
  transactions: state.transactionReducers.transactions,
  events: state.eventReducer.events,
  reservations: state.edit_host.reservations,
});

export default connect(mapStateToProps, {
  fetchExperiences,
  fetchHomes,
  fetch_customers_async,
  fetch_transactions_async,
  fetctHostProfile,
  // fetchLocationAsync,
  async_fetch_events,
  homeReservationAsync,
})(Dashboard);
