import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import * as Yup from "yup";
import { Formik } from "formik";
import { Box, Container, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { signin } from "src/actions/loginAction";

const Login = ({ signin }) => {
  const navigate = useNavigate();

  const { user } = useParams();

  useEffect(() => {
    signin(user, navigate);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Helmet>
        <title>Tubayo Host | Login</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="sm">
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <img
              alt="banare"
              style={{ width: "30%", height: "30%" }}
              src="/static/images/banna.png"
            />
          </Box>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
              password: Yup.string().max(255).required("Password is required"),
            })}
            onSubmit={async (values) => {
              await signin(values, navigate);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  sx={{
                    mb: 3,
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Typography
                    color="textPrimary"
                    variant="h2"
                    justifyContent="center"
                  >
                    Sign in
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Welcome back our dear host, please wait as your dasboard
                    loads to continue.
                  </Typography>
                </Box>
                {user.length < 1 || user === ":user" ? (
                  <Box
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      display: "flex",
                      ml: -1,
                    }}
                  >
                    <Typography color="textSecondary" variant="body1">
                      Back to travel?{" "}
                      <a
                        href="https://tubayo.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          color: "teal",
                          textDecoration: "underline",
                          fontWeight: "bold",
                        }}
                      >
                        Tubayo
                      </a>
                    </Typography>
                  </Box>
                ) : null}
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default connect(null, { signin })(Login);
