import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Box, Container } from "@material-ui/core";
import { connect } from "react-redux";
import ExperienceListResults from "src/components/experiences/ExperienceListResults";
// import ExperienceListToolbar from "src/components/experiences/ExperienceListToolbar";
import { fetchExperiences } from "src/actions/ExperienceActions";

const ExperienceList = ({ fetchExperiences, experiences }) => {
  useEffect(() => {
    const fetchItemsAsync = async () => {
      await fetchExperiences();
    };

    fetchItemsAsync();
  }, [fetchExperiences]);

  return (
    <>
      <Helmet>
        <title>Tubayo Host | Experience</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          {/* <ExperienceListToolbar /> */}
          <Box sx={{ pt: 3 }}>
            <ExperienceListResults experiences={experiences} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  experiences: state.experiences.experience,
});

export default connect(mapStateToProps, { fetchExperiences })(ExperienceList);
