import axios from "axios";
import { toast } from "react-toastify";
import { APIURL } from "../utils/misc";
import { HOST_PROFILE_TYPES } from "./types";

export const editHost = (data, hostId, navigate) => {
  return async () => {
    try {
      const res = await axios.put(`${APIURL}hosts/${hostId}`, data);
      localStorage.setItem("USERNAME", res.data.newHost.userName);
      localStorage.setItem("PHOTO", res.data.newHost.profilePic);
      toast.success("Profile updated successfully");
      navigate("/app/dashboard");
      window.location.reload();
    } catch (error) {
      toast.error("Error updating profile, please try again");
    }
  };
};

export const onAddSubHost = (userId) => async () => {
  try {
    const host = localStorage.getItem("HOST_ID");
    const res = await axios.patch(`${APIURL}hosts/${host}/link/${userId}`);
    if (res.data.status === "fail") {
      toast.error(res.data.message);
    } else {
      toast.success(res.data.message);
      window.location.reload();
    }
  } catch (error) {
    toast.error("Error adding subhost, please try again");
  }
};

export const onRemoveSubHost = (userId) => async () => {
  try {
    const host = localStorage.getItem("HOST_ID");
    const res = await axios.patch(
      `${APIURL}hosts/unlink/${userId}?host=${host}`
    );
    if (res.data.status === "fail") {
      toast.error(res.data.message);
    } else {
      toast.success(res.data.message);
      window.location.reload();
    }
  } catch (error) {
    toast.error("Error adding subhost, please try again");
  }
};

export const fetctHostProfile = (navigate) => {
  return async (dispatch) => {
    const host = localStorage.getItem("HOST_ID");
    try {
      const res = await axios.get(`${APIURL}hosts/${host}`);
      dispatch({
        type: HOST_PROFILE_TYPES.HOST_PROFILE_FETCH_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: HOST_PROFILE_TYPES.HOST_PROFILE_ERROR,
        payload: "We're working on it, please try again while we implement..",
      });
    }
  };
};

const homeReservationsSuccess = (payload) => ({
  type: HOST_PROFILE_TYPES.HOST_RESERVATIONS_SUCCESS,
  payload,
});

export const homeReservationAsync = () => async (dispatch) => {
  const host = localStorage.getItem("HOST_ID");
  try {
    const res = await axios.get(`${APIURL}reservations/host/${host}`);
    dispatch(homeReservationsSuccess(res.data.reservations));
  } catch (error) {}
};

export const updateReservationAsync = (data, reservationId) => async () => {
  try {
    await axios.patch(`${APIURL}reservations/${reservationId}`, data);
    toast.success(`Reservation has been ${data.status} successfully.`);
    window.location.reload();
  } catch (error) {
    toast.error(`Kindly check your connection and try again.`);
  }
};
