import { TRANSCTION_TYPES } from "../../actions/types";
import { calculate_total_transactions } from "../../utils/misc";

const INITIAL_STATE = {
  transactions: 0,
  transactions_data: [],
};

const transactionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TRANSCTION_TYPES.FETCH_TRANSACTIONS:
      return {
        ...state,
        transactions: calculate_total_transactions(action.payload),
        transactions_data: action.payload,
      };

    default:
      return state;
  }
};

export default transactionReducer;
