import { AUTH_TYPES } from '../../actions/types';

const initState = []
const resetPasswordReducer = (state = initState, action) => {
    switch (action.type) {
        case AUTH_TYPES.RESETPASSWORD:
            return { ...state, msg: action.payload }
        case AUTH_TYPES.RESETPASSWORD_ERROR:
            return { ...state, errorMessage: action.payload }
        default:
            return state
    }
}

export default resetPasswordReducer