import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  Box,
  Divider,
} from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import RefreshIcon from "@material-ui/icons/Refresh";
import { useNavigate } from "react-router-dom";

const Budget = ({ title, count, icon, fill, href, status }) => {
  const navigate = useNavigate();
  return (
    <Card
      sx={{ height: "100%" }}
      onClick={() => navigate(href)}
      style={{ cursor: "pointer" }}
    >
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
          <Grid item>
            <Avatar style={{ backgroundColor: fill, height: 56, width: 56 }}>
              {icon}
            </Avatar>
          </Grid>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              {title}
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {count}
            </Typography>
          </Grid>
        </Grid>

        {status ? (
          <>
            <Divider style={{ marginTop: 20 }} />
            <Grid
              container
              spacing={3}
              style={{ justifyContent: "space-between" }}
            >
              <Grid item>
                <Box
                  style={{
                    alignItems: "center",
                    display: "flex",
                    paddingTop: 2,
                  }}
                >
                  <ArrowUpwardIcon style={{ color: "#66bb6a" }} />
                  <Typography
                    variant="h5"
                    style={{
                      color: "#66bb6a",
                      marginRight: 5,
                    }}
                  >
                    {status.approved}
                  </Typography>
                  <Typography color="textSecondary" variant="caption">
                    Approved
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box
                  style={{
                    alignItems: "center",
                    display: "flex",
                    paddingTop: 2,
                  }}
                >
                  <RefreshIcon style={{ color: "#FFA500" }} />
                  <Typography
                    variant="h5"
                    style={{
                      color: "#FFA500",
                      marginRight: 5,
                    }}
                  >
                    {status.pending}
                  </Typography>
                  <Typography color="textSecondary" variant="caption">
                    Pending
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </>
        ) : null}
      </CardContent>
    </Card>
  );
};

export default Budget;
