import { useNavigate } from "react-router-dom";
import {
  Divider,
  Grid,
  TextField,
  Container,
  Button,
  colors,
  Card,
  CardHeader,
  CardContent,
  makeStyles,
  Typography,
  Avatar,
  Box,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@material-ui/core";

import SecurityIcon from "@material-ui/icons/Security";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import { Formik } from "formik";
import { connect } from "react-redux";
import {
  async_update_event,
  uploadEventCoverImage,
  deleteImageFile,
} from "src/actions/event.action";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import PlacesAutocomplete from "react-places-autocomplete";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { Delete, Add, Cancel } from "@material-ui/icons";
import { compressedFile } from "src/utils/misc";

const useStyles = makeStyles(() => ({
  root: {
    padding: 3,
    marginTop: 30,
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: colors.grey[300],
  },
  content: {
    marginTop: 20,
  },
  actions: {
    justifyContent: "center",
  },
  dateInput: {
    borderWidth: 1,
    borderColor: "#dedede",
    borderRadius: 5,
    cursor: "pointer",
  },
  showStartDatePicker: {
    display: "block",
  },
  hideStartDatePicker: {
    display: "none",
  },
  showEndDatePicker: {
    display: "block",
  },
  hideEndDatePicker: {
    display: "none",
  },
  input: {
    display: "none",
  },
  avatar: {
    height: 150,
    width: 150,
    borderRadius: 0,
  },
  saveButton: {
    color: "#fff",
    backgroundColor: colors.green[600],
    "&:hover": {
      backgroundColor: colors.green[900],
    },
    width: "30%",
  },
}));

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginImagePreview
);

function EditEvents({
  event,
  async_update_event,
  uploadEventCoverImage,
  deleteImageFile,
}) {
  const [formState, setFormState] = useState({
    eventName: "",
    summary: "",
    startDate: new Date(),
    startTime: "10:00",
    endDate: new Date(),
    endTime: "10:00",
    fullDesc: "",
    priceRate: "Per person",
    price: "",
    coverImage: "",
  });
  const [ticketPriceState, setTicketPriceState] = useState(event.tickets);
  const [address, setAddress] = useState(`${event.address}`);
  const [coverImage, setCoverImage] = useState(`${event.coverImage}`);
  const [cover, setCover] = useState([]);
  const [cover_file, setCoverFile] = useState({});
  const navigate = useNavigate();
  const classes = useStyles();

  useEffect(() => {
    setFormState({
      ...event,
    });
  }, [event]);

  const handleRemoveImage = async () => {
    try {
      const Key = coverImage.split("/")[3];
      await deleteImageFile({ Key });
      setCoverImage("");
      setCoverFile({});
    } catch (error) {
      toast.error(error.message);
    }
  };

  let handleChangeTicket = (i, e) => {
    let newTicketPriceState = [...ticketPriceState];
    newTicketPriceState[i][e.target.name] = e.target.value;
    setTicketPriceState(newTicketPriceState);
  };

  let addFormFields = () => {
    setTicketPriceState([
      ...ticketPriceState,
      {
        category: "",
        price: "",
        priceRate: "",
      },
    ]);
  };

  let removeFormFields = (i) => {
    let newTicketPriceState = [...ticketPriceState];
    newTicketPriceState.splice(i, 1);
    setTicketPriceState(newTicketPriceState);
  };

  return (
    <Container className={classes.root}>
      <Card>
        <CardHeader subheader="Add Event Details" title="Event" />
        <Divider className={classes.divider} />
        <CardContent style={{ marginTop: 20 }}>
          <Formik
            initialValues={event}
            onSubmit={async (values, { resetForm }) => {
              if (!coverImage)
                return toast.warning("Please add event cover image!");
              const obj = {
                ...values,
                coverImage,
                address,
                tickets: ticketPriceState,
              };

              await async_update_event(obj, event._id, navigate);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form className={classes.form} onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Event Name"
                      name="eventName"
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Event Name"
                      defaultValue={event.eventName}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Country"
                      name="country"
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Country"
                      defaultValue={event.country}
                    />
                  </Grid>

                  <Grid item xs={12} md={12} lg={12}>
                    <PlacesAutocomplete
                      value={address}
                      onChange={setAddress}
                      onSelect={setAddress}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <TextField
                            {...getInputProps({
                              variant: "outlined",
                              placeholder: "Add Address",
                              className: "form-control m-2",
                              style: { height: "50px", width: "100%" },
                            })}
                          />
                          <div>
                            {loading ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginTop: 10,
                                }}
                              >
                                <div className="loader" />
                              </div>
                            ) : null}
                            {suggestions.map((suggestion) => {
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    style: {
                                      backgroundColor: suggestion.active
                                        ? "#ececec"
                                        : "#fff",
                                      padding: 5,
                                      marginTop: 10,
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    },
                                  })}
                                >
                                  <LocationCityIcon
                                    style={{ marginRight: 10 }}
                                  />
                                  <Typography variant="inherit">
                                    {suggestion.description}
                                  </Typography>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Start Date"
                      name="startDate"
                      type="date"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      defaultValue={new Date(event.startDate)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Start Time"
                      name="startTime"
                      type="time"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      defaultValue={formState.startTime}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="End Date"
                      name="endDate"
                      type="date"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      defaultValue={new Date(event.endDate)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="End Time"
                      name="endTime"
                      type="time"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      defaultValue={formState.endTime}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Enter brief description about the event"
                      name="summary"
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Event summary"
                      defaultValue={event.summary}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Event Description"
                      name="fullDesc"
                      type="text"
                      placeholder="Full event description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      defaultValue={event.fullDesc}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label="Extra Information"
                      name="extraInfo"
                      type="text"
                      placeholder="Extra information about the event like restrictions"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      defaultValue={event.extraInfo}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5">
                      Set Ticket Price categories
                    </Typography>
                    {ticketPriceState.map((item, idx) => (
                      <Box key={idx} mt={3}>
                        <FormControl
                          style={{ minWidth: "100%" }}
                          variant="outlined"
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Select Ticket Category
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            style={{ marginBottom: 17 }}
                            onChange={(e) => handleChangeTicket(idx, e)}
                            name="category"
                            label="Select Ticket Category"
                            autoWidth
                            defaultValue={item.category}
                          >
                            {["Early Bird", "Ordinary", "VIP", "VVIP"].map(
                              (item) => (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                        <TextField
                          style={{ marginBottom: 17 }}
                          variant="outlined"
                          fullWidth
                          label="Price for how many"
                          name="priceRate"
                          type="text"
                          onChange={(e) => handleChangeTicket(idx, e)}
                          placeholder="Per person , Per table"
                          defaultValue={item.priceRate}
                        />
                        <TextField
                          variant="outlined"
                          fullWidth
                          id="price"
                          label="Enter Event price? (USD)"
                          name="price"
                          onChange={(e) => handleChangeTicket(idx, e)}
                          placeholder="Event Price"
                          defaultValue={item.price}
                        />
                        {/* </Grid> */}
                      </Box>
                    ))}
                    <Box>
                      {ticketPriceState.length < 4 ? (
                        <Button
                          variant="contained"
                          color="secondary"
                          style={{ marginTop: 5 }}
                          startIcon={<Add />}
                          onClick={addFormFields}
                        >
                          Add Ticket
                        </Button>
                      ) : null}
                      {ticketPriceState.length > 1 ? (
                        <Button
                          variant="contained"
                          color="default"
                          style={{ marginTop: 5, marginLeft: 10 }}
                          startIcon={<Cancel />}
                          onClick={removeFormFields}
                        >
                          Remove Ticket
                        </Button>
                      ) : null}
                    </Box>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    {coverImage ? (
                      <Box style={{ position: "relative", marginBottom: 20 }}>
                        <Avatar
                          style={{
                            width: 150,
                            height: 200,
                            borderRadius: 5,
                          }}
                          src={coverImage}
                        />
                        <Box style={{ position: "absolute", top: 8, left: 5 }}>
                          <Delete
                            style={{
                              color: colors.red[500],
                              cursor: "pointer",
                            }}
                            onClick={handleRemoveImage}
                          />
                        </Box>
                      </Box>
                    ) : (
                      <>
                        <Typography variant="h5" display="block" gutterBottom>
                          Add Event cover image here.
                        </Typography>
                        <FilePond
                          files={cover}
                          onupdatefiles={setCover}
                          allowMultiple={false}
                          allowImageResize={true}
                          allowRemove={true}
                          acceptedFileTypes={["image/*"]}
                          server={{
                            process: async (
                              fieldName,
                              file,
                              metadata,
                              load,
                              error,
                              progress,
                              abort
                            ) => {
                              try {
                                const image = await compressedFile(file);
                                const formData = new FormData();
                                formData.append("imageCover", image);
                                const res = await uploadEventCoverImage(
                                  formData
                                );
                                setCoverFile(res.file_data);
                                setCoverImage(res.file_data.path);
                                load(res.message);
                              } catch (err) {
                                console.log(error);
                                error(err.message);
                              }
                              return {
                                abort: () => {
                                  abort();
                                },
                              };
                            },
                            revert: async (uniqueFileId, load) => {
                              const keyObject = { Key: cover_file.Key };
                              try {
                                const res = await deleteImageFile(keyObject);
                                setCoverFile({});
                                load(res.message);
                              } catch (error) {
                                console.log(error);
                                load();
                              }
                            },
                          }}
                          name="file"
                          labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                        />
                      </>
                    )}
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  startIcon={<SecurityIcon />}
                >
                  Submit
                </Button>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  event: state.eventReducer.event,
});

export default connect(mapStateToProps, {
  async_update_event,
  uploadEventCoverImage,
  deleteImageFile,
})(EditEvents);
