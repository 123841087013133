import { NOTIFICATION_TYPES } from "src/actions/types";

const initialState = {
  message: "",
  error: false,
};
const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION_TYPES.NOTICATION_SUCCESS:
      return {
        ...state,
        message: action.message,
        error: false,
      };
    case NOTIFICATION_TYPES.NOTICATION_FAILURE:
      return {
        ...state,
        message: action.message,
        error: true,
      };
    case NOTIFICATION_TYPES.NOTICATION_EMPTY:
      return {
        ...state,
        message: action.message,
        error: false,
      };
    default:
      return state;
  }
};

export default notificationReducer;
