import React from "react";
import { Helmet } from "react-helmet";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Box,
  Container,
  makeStyles,
} from "@material-ui/core";
// import { makeStyles } from "@material-ui/styles";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";
import { connect } from "react-redux";
import { fetch_transactions_async } from "src/actions/transaction.actions";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    marginTop: 20,
    paddingBottom: 10,
    paddingTop: 10,
    paddingLeft: 10,
  },
  cursorPointer: {
    cursor: "pointer",
  },
  viewBtns: {
    textAlign: "center",
    color: "#fff",
    fontWeight: "500",
  },
}));

function ViewTransactionList({ fetch_transactions_async, transactions }) {
  const classes = useStyles();

  React.useEffect(() => {
    const async_fetch_collateral = async () => {
      await fetch_transactions_async();
    };
    async_fetch_collateral();
  }, [fetch_transactions_async]);
  return (
    <Container className={classes.root} title="Users list">
      <Helmet>
        <title>Tubayo Host | Transactions</title>
      </Helmet>
      <Box>
        <Card>
          <PerfectScrollbar>
            <Box minWidth={600}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Customer Names</TableCell>
                    <TableCell>Customer Email</TableCell>
                    <TableCell>Listing paid for</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactions &&
                    transactions.slice(0, 10).map((transaction, index) => (
                      <TableRow
                        hover
                        key={index}
                        className={classes.cursorPointer}
                      >
                        <TableCell>{`${transaction.user_names}`}</TableCell>
                        <TableCell>{transaction.user_email}</TableCell>
                        <TableCell>{transaction.listing_name}</TableCell>
                        <TableCell>{transaction.paid_amount}</TableCell>
                        <TableCell>
                          {moment(transactions.create_date).format(
                            "DD/MM/YYYY"
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={transactions.length}
            page={1}
            rowsPerPage={10}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Card>
      </Box>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  transactions: state.transactionReducers.transactions_data,
});

export default connect(mapStateToProps, { fetch_transactions_async })(
  ViewTransactionList
);
