import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Box, Container } from "@material-ui/core";
import CustomerListResults from "src/components/product/CustomerListResults";
import { connect } from "react-redux";
import { fetch_customers_async } from "src/actions/customer.actions";

const CustomersList = ({ fetch_customers_async, customers }) => {
  useEffect(() => {
    const fetchItemsAsync = async () => {
      await fetch_customers_async();
    };
    fetchItemsAsync();
  }, [fetch_customers_async]);

  return (
    <>
      <Helmet>
        <title>Tubayo Host | Customers</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Box sx={{ pt: 3 }}>
            <CustomerListResults customers={customers} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  customers: state.customers.customers,
});

export default connect(mapStateToProps, { fetch_customers_async })(
  CustomersList
);
