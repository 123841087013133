export const AUTH_TYPES = {
  AUTH_SIGN_IN: "AUTH_SIGN_IN",
  AUTH_SIGN_OUT: "AUTH_SIGN_OUT",
  AUTH_ERROR: "AUTH_ERROR",
  FORGOTPASSWORD: "FORGOTPASSWORD",
  FORGOTPASSWORD_ERROR: "FORGOTPASSWORD_ERROR",
  RESETPASSWORD: "RESETPASSWORD",
  RESETPASSWORD_ERROR: "RESETPASSWORD_ERROR",
};

export const EXPERIENCE_TYPE = {
  SET_EXPERIENCE: "SET_EXPERIENCE",
  ADD_EXPERIENCE: "ADD_EXPERIENCE",
  GET_EXPERIENCE: "GET_EXPERIENCE",
  EDIT_EXPERIENCE: "EDIT_EXPERIENCE",
  EXPERIENCE_ERROR: "EXPERIENCE_ERROR",
};

export const HOST_PROFILE_TYPES = {
  HOST_PROFILE_FETCH_SUCCESS: "HOST_PROFILE_FETCH_SUCCESS",
  HOST_PROFILE_EDIT: "HOST_PROFILE_EDIT",
  HOST_PROFILE_ERROR: "HOST_PROFILE_ERROR",
  HOST_RESERVATIONS_SUCCESS: "HOST_RESERVATIONS_SUCCESS",
};

export const LOCATION_TYPES = {
  FETCH_LOCATION: "FETCH_LOCATION",
};

export const CUSTOMER_TYPES = {
  FETCH_CUSTOMERS_SUCCESS: "FETCH_CUSTOMERS_SUCCESS",
  ADD_CUSTOMER_SUCCESS: "ADD_CUSTOMER_SUCCESS",
};

export const TRANSCTION_TYPES = {
  FETCH_TRANSACTIONS: "FETCH_TRANSACTIONS",
  FETCH_TRANSACTION_DETAILS: "FETCH_TRANSACTION_DETAILS",
};

export const HOME_TYPE = {
  SET_HOME: "SET_HOME",
  ADD_HOME: "ADD_HOME",
  GET_HOME: "GET_HOME",
  EDIT_HOME: "EDIT_HOME",
  HOME_ERROR: "HOME_ERROR",
};

export const EVENT_TYPE = {
  GET_EVENTS: "GET_EVENTS",
  ADD_EVENT: "ADD_EVENT",
  GET_EVENT: "GET_EVENT",
  EDIT_EVENT: "EDIT_EVENT",
  EVENT_BOOKING_SUCCESS: "EVENT_BOOKING_SUCCESS",
};

export const NOTIFICATION_TYPES = {
  NOTICATION_SUCCESS: "NOTICATION_SUCCESS",
  NOTICATION_FAILURE: "NOTICATION_FAILURE",
  NOTICATION_EMPTY: "NOTICATION_EMPTY",
};

export const FILE_TYPES = {
  UPLOAD_EXPERICENCE_COVER: "UPLOAD_EXPERICENCE_COVER",
};

export const SET_BOOKING = "SET_BOOKING";
export const ADD_BOOKING = "ADD_BOOKING";
export const EVENT_BOOKINGS = "EVENT_BOOKINGS";

export const EDIT_HOST = "EDIT_HOST";
export const HOST_ERROR = "HOST_ERROR";
