const Logo = (props) => (
  <img
    alt="Logo"
    style={{ width: 40, height: 40 }}
    src="/static/images/logo.png"
    {...props}
  />
);

export default Logo;
