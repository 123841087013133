import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";

const CustomerListResults = ({ customers, ...rest }) => (
  <Card {...rest}>
    <PerfectScrollbar>
      <Box sx={{ minWidth: 1050 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell>Customer Name</TableCell>
              <TableCell>Booking count</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customers.slice(0, 10).map((customer, idx) => (
              <TableRow hover key={customer.id}>
                <TableCell>{customers.length - idx}</TableCell>
                <TableCell>{`${customer.firstName} ${customer.lastName}`}</TableCell>
                <TableCell>{customer.booking_count}</TableCell>
                <TableCell>{customer.email}</TableCell>
                <TableCell>{customer.phoneNumber}</TableCell>
                <TableCell>
                  {moment(customer.createAt).format("DD/MM/YYYY")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </PerfectScrollbar>
    <TablePagination
      component="div"
      count={0}
      // onPageChange={handlePageChange}
      // onRowsPerPageChange={handleLimitChange}
      // page={page}
      // rowsPerPage={limit}
      rowsPerPageOptions={[5, 10, 25]}
    />
  </Card>
);

export default CustomerListResults;
