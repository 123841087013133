import { EXPERIENCE_TYPE } from "../../actions/types";

const initState = {
  experience: [],
  errorMessage: "",
};

const fetchedExperience = (state = initState, action) => {
  switch (action.type) {
    case EXPERIENCE_TYPE.GET_EXPERIENCE:
      return { ...state, experience: action.payload };
    case EXPERIENCE_TYPE.EXPERIENCE_ERROR:
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
};

export default fetchedExperience;
