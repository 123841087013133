import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@material-ui/core";
import { connect } from "react-redux";
import { editHost } from "src/actions/EditHost";

const AccountProfileDetails = ({ host, editHost, ...rest }) => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    phoneNumber: "",
    email: "",
    dateOfBirth: "",
    location: "",
    briefBio: "",
    accountNumber: "",
    accountName: "",
    mobileMoneyNumber: "",
    bankSwiftCode: "",
    bankName: "",
    bankBranch: "",
  });

  useEffect(() => {
    setValues(host);
  }, [host]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await editHost(values, host?._id, navigate);
  };

  return (
    <form autoComplete="off" noValidate {...rest} onSubmit={handleSubmit}>
      <Card>
        <CardHeader subheader="The information can be edited" title="Profile" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText="Please specify the first name"
                label="First name"
                name="firstName"
                onChange={handleChange}
                defaultValue={host?.firstName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Last name"
                name="lastName"
                onChange={handleChange}
                defaultValue={host?.lastName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="User name"
                name="userName"
                onChange={handleChange}
                defaultValue={host?.userName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Email Address"
                name="email"
                onChange={handleChange}
                defaultValue={host?.email}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Phone Number"
                name="phoneNumber"
                onChange={handleChange}
                type="text"
                defaultValue={host?.phoneNumber}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Mobile Money Number"
                name="mobileMoneyNumber"
                onChange={handleChange}
                defaultValue={host?.mobileMoneyNumber}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Account Name"
                name="accountName"
                onChange={handleChange}
                defaultValue={host?.accountName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Account Number"
                name="accountNumber"
                onChange={handleChange}
                defaultValue={host?.accountNumber}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Bank Name"
                name="bankName"
                onChange={handleChange}
                defaultValue={host?.bankName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Bank Branch"
                name="bankBranch"
                onChange={handleChange}
                defaultValue={host?.bankBranch}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Bank Swift Code"
                name="bankSwiftCode"
                onChange={handleChange}
                defaultValue={host?.bankSwiftCode}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Date Of Birth"
                name="dateOfBirth"
                onChange={handleChange}
                type="date"
                defaultValue={host?.dateOfBirth}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Location"
                name="location"
                onChange={handleChange}
                defaultValue={host?.location}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Brief Bio"
                name="briefBio"
                onChange={handleChange}
                defaultValue={host?.briefBio}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            p: 2,
          }}
        >
          <Button color="primary" variant="contained" type="submit">
            Save details
          </Button>
        </Box>
      </Card>
    </form>
  );
};

const mapStateToProps = (state) => ({
  host: state.edit_host?.host,
});

export default connect(mapStateToProps, { editHost })(AccountProfileDetails);
