import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
} from "@material-ui/core";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import { connect } from "react-redux";
import {
  uploadHomeCoverImage,
  deleteHomeImageFile,
  editHome,
} from "src/actions/HomeActions";
import { toast } from "react-toastify";
import { compressedFile } from "src/utils/misc";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginImagePreview
);

const UploadHomeImages = ({
  uploadHomeCoverImage,
  deleteHomeImageFile,
  editHome,
  home,
  ...rest
}) => {
  const navigate = useNavigate();

  const [cover, setCover] = useState([]);
  const [One, setOne] = useState([]);
  const [Tow, setTow] = useState([]);
  const [Three, setThree] = useState([]);
  const [Four, setFour] = useState([]);
  const [Five, setFive] = useState([]);
  const [Six, setSix] = useState([]);
  const [Sev, setSev] = useState([]);
  const [Eight, setEight] = useState([]);
  const [Nine, setNine] = useState([]);
  const [Ten, setTen] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [cover_file, setCoverFile] = useState({});
  const [One_file, setOneFile] = useState({});
  const [Tow_file, setTowFile] = useState({});
  const [Three_file, setThreeFile] = useState({});
  const [Four_file, setFourFile] = useState({});
  const [Five_file, setFiveFile] = useState({});
  const [Six_file, setSixFile] = useState({});
  const [Sev_file, setSevFile] = useState({});
  const [Eight_file, setEightFile] = useState({});
  const [Nine_file, setNineFile] = useState({});
  const [Ten_file, setTenFile] = useState({});

  const handleSubmit = async () => {
    if (gallery.length < 3 || !cover_file.path) {
      toast.warning("Add Cover image and atleast 3 other images");
    } else {
      const obj = {
        ...home,
        homeCoverImage: cover_file.path,
        homeImages: gallery,
      };
      await editHome(obj, home._id, navigate);
    }
  };

  return (
    <Container maxWidth={true} style={{ marginTop: 50 }}>
      <form {...rest}>
        <Card>
          <CardHeader subheader="Home Images" title="Upload Images" />
          <Divider />
          <CardContent>
            <Grid container spacing={6} wrap="wrap">
              <Grid item xs={12}>
                <FilePond
                  files={cover}
                  onupdatefiles={setCover}
                  allowMultiple={false}
                  allowImageResize={true}
                  allowRemove={true}
                  acceptedFileTypes={["image/*"]}
                  server={{
                    process: async (
                      fieldName,
                      file,
                      metadata,
                      load,
                      error,
                      progress,
                      abort
                    ) => {
                      try {
                        const image = await compressedFile(file);
                        const formData = new FormData();
                        formData.append("imageCover", image);
                        const res = await uploadHomeCoverImage(formData);
                        setCoverFile(res.file_data);
                        load(res.message);
                      } catch (err) {
                        console.log(error);
                        error(err.message);
                      }
                      return {
                        abort: () => {
                          abort();
                        },
                      };
                    },
                    revert: async (uniqueFileId, load) => {
                      const keyObject = { Key: cover_file.Key };
                      try {
                        const res = await deleteHomeImageFile(keyObject);
                        setCoverFile({});
                        load(res.message);
                      } catch (error) {
                        console.log(error);
                        load();
                      }
                    },
                  }}
                  name="file"
                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                />
              </Grid>
            </Grid>
            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            {/* <Grid container spacing={6} wrap="wrap"> */}

            <Grid container spacing={6} wrap="wrap">
              <Grid item md={4} xs={12}>
                <FilePond
                  files={One}
                  onupdatefiles={setOne}
                  allowMultiple={false}
                  allowImageResize={true}
                  allowRemove={true}
                  acceptedFileTypes={["image/*"]}
                  server={{
                    process: async (
                      fieldName,
                      file,
                      metadata,
                      load,
                      error,
                      progress,
                      abort
                    ) => {
                      try {
                        const image = await compressedFile(file);
                        const formData = new FormData();
                        formData.append("imageCover", image);
                        const res = await uploadHomeCoverImage(formData);
                        setOneFile(res.file_data);
                        setGallery([...gallery, res.file_data.path]);

                        load(res.message);
                      } catch (err) {
                        console.log(error);
                        error(err.message);
                      }
                      return {
                        abort: () => {
                          abort();
                        },
                      };
                    },
                    revert: async (uniqueFileId, load) => {
                      const keyObject = { Key: One_file.Key };
                      try {
                        const res = await deleteHomeImageFile(keyObject);
                        const newGallery = gallery.filter(
                          (item) => item !== One_file.path
                        );
                        setGallery(newGallery);
                        setOneFile({});
                        load(res.message);
                      } catch (error) {
                        console.log(error);
                        load();
                      }
                    },
                  }}
                  // name="files"
                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <FilePond
                  files={Tow}
                  onupdatefiles={setTow}
                  allowMultiple={false}
                  allowImageResize={true}
                  allowRemove={true}
                  acceptedFileTypes={["image/*"]}
                  server={{
                    process: async (
                      fieldName,
                      file,
                      metadata,
                      load,
                      error,
                      progress,
                      abort
                    ) => {
                      try {
                        const image = await compressedFile(file);
                        const formData = new FormData();
                        formData.append("imageCover", image);
                        const res = await uploadHomeCoverImage(formData);
                        setTowFile(res.file_data);
                        setGallery([...gallery, res.file_data.path]);

                        load(res.message);
                      } catch (err) {
                        console.log(error);
                        error(err.message);
                      }
                      return {
                        abort: () => {
                          abort();
                        },
                      };
                    },
                    revert: async (uniqueFileId, load) => {
                      const keyObject = { Key: Tow_file.Key };
                      try {
                        const res = await deleteHomeImageFile(keyObject);
                        const newGallery = gallery.filter(
                          (item) => item !== Tow_file.path
                        );
                        setGallery(newGallery);
                        setTowFile({});
                        load(res.message);
                      } catch (error) {
                        console.log(error);
                        load();
                      }
                    },
                  }}
                  // name="files"
                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <FilePond
                  files={Three}
                  onupdatefiles={setThree}
                  allowMultiple={false}
                  allowImageResize={true}
                  allowRemove={true}
                  acceptedFileTypes={["image/*"]}
                  server={{
                    process: async (
                      fieldName,
                      file,
                      metadata,
                      load,
                      error,
                      progress,
                      abort
                    ) => {
                      try {
                        const image = await compressedFile(file);
                        const formData = new FormData();
                        formData.append("imageCover", image);
                        const res = await uploadHomeCoverImage(formData);
                        setThreeFile(res.file_data);
                        setGallery([...gallery, res.file_data.path]);

                        load(res.message);
                      } catch (err) {
                        console.log(error);
                        error(err.message);
                      }
                      return {
                        abort: () => {
                          abort();
                        },
                      };
                    },
                    revert: async (uniqueFileId, load) => {
                      const keyObject = { Key: Three_file.Key };
                      try {
                        const res = await deleteHomeImageFile(keyObject);
                        const newGallery = gallery.filter(
                          (item) => item !== Three_file.path
                        );
                        setGallery(newGallery);
                        setThreeFile({});
                        load(res.message);
                      } catch (error) {
                        console.log(error);
                        load();
                      }
                    },
                  }}
                  // name="files"
                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <FilePond
                  files={Four}
                  onupdatefiles={setFour}
                  allowMultiple={false}
                  allowImageResize={true}
                  allowRemove={true}
                  acceptedFileTypes={["image/*"]}
                  server={{
                    process: async (
                      fieldName,
                      file,
                      metadata,
                      load,
                      error,
                      progress,
                      abort
                    ) => {
                      try {
                        const formData = new FormData();
                        formData.append("imageCover", file);
                        const res = await uploadHomeCoverImage(formData);
                        setFourFile(res.file_data);
                        setGallery([...gallery, res.file_data.path]);

                        load(res.message);
                      } catch (err) {
                        console.log(error);
                        error(err.message);
                      }
                      return {
                        abort: () => {
                          abort();
                        },
                      };
                    },
                    revert: async (uniqueFileId, load) => {
                      const keyObject = { Key: Four_file.Key };
                      try {
                        const res = await deleteHomeImageFile(keyObject);
                        const newGallery = gallery.filter(
                          (item) => item !== Four_file.path
                        );
                        setGallery(newGallery);
                        setFourFile({});
                        load(res.message);
                      } catch (error) {
                        console.log(error);
                        load();
                      }
                    },
                  }}
                  // name="files"
                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <FilePond
                  files={Five}
                  onupdatefiles={setFive}
                  allowMultiple={false}
                  allowImageResize={true}
                  allowRemove={true}
                  acceptedFileTypes={["image/*"]}
                  server={{
                    process: async (
                      fieldName,
                      file,
                      metadata,
                      load,
                      error,
                      progress,
                      abort
                    ) => {
                      try {
                        const image = await compressedFile(file);
                        const formData = new FormData();
                        formData.append("imageCover", image);
                        const res = await uploadHomeCoverImage(formData);
                        setFiveFile(res.file_data);
                        setGallery([...gallery, res.file_data.path]);

                        load(res.message);
                      } catch (err) {
                        console.log(error);
                        error(err.message);
                      }
                      return {
                        abort: () => {
                          abort();
                        },
                      };
                    },
                    revert: async (uniqueFileId, load) => {
                      const keyObject = { Key: Five_file.Key };
                      try {
                        const res = await deleteHomeImageFile(keyObject);
                        const newGallery = gallery.filter(
                          (item) => item !== Five_file.path
                        );
                        setGallery(newGallery);
                        setFiveFile({});
                        load(res.message);
                      } catch (error) {
                        console.log(error);
                        load();
                      }
                    },
                  }}
                  // name="files"
                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <FilePond
                  files={Six}
                  onupdatefiles={setSix}
                  allowMultiple={false}
                  allowImageResize={true}
                  allowRemove={true}
                  acceptedFileTypes={["image/*"]}
                  server={{
                    process: async (
                      fieldName,
                      file,
                      metadata,
                      load,
                      error,
                      progress,
                      abort
                    ) => {
                      try {
                        const image = await compressedFile(file);
                        const formData = new FormData();
                        formData.append("imageCover", image);
                        const res = await uploadHomeCoverImage(formData);
                        setSixFile(res.file_data);
                        setGallery([...gallery, res.file_data.path]);

                        load(res.message);
                      } catch (err) {
                        console.log(error);
                        error(err.message);
                      }
                      return {
                        abort: () => {
                          abort();
                        },
                      };
                    },
                    revert: async (uniqueFileId, load) => {
                      const keyObject = { Key: Six_file.Key };
                      try {
                        const res = await deleteHomeImageFile(keyObject);
                        const newGallery = gallery.filter(
                          (item) => item !== Six_file.path
                        );
                        setGallery(newGallery);
                        setSixFile({});
                        load(res.message);
                      } catch (error) {
                        console.log(error);
                        load();
                      }
                    },
                  }}
                  // name="files"
                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <FilePond
                  files={Sev}
                  onupdatefiles={setSev}
                  allowMultiple={false}
                  allowImageResize={true}
                  allowRemove={true}
                  acceptedFileTypes={["image/*"]}
                  server={{
                    process: async (
                      fieldName,
                      file,
                      metadata,
                      load,
                      error,
                      progress,
                      abort
                    ) => {
                      try {
                        const image = await compressedFile(file);
                        const formData = new FormData();
                        formData.append("imageCover", image);
                        const res = await uploadHomeCoverImage(formData);
                        setSevFile(res.file_data);
                        setGallery([...gallery, res.file_data.path]);
                        load(res.message);
                      } catch (err) {
                        console.log(error);
                        error(err.message);
                      }
                      return {
                        abort: () => {
                          abort();
                        },
                      };
                    },
                    revert: async (uniqueFileId, load) => {
                      const keyObject = { Key: Sev_file.Key };
                      try {
                        const res = await deleteHomeImageFile(keyObject);
                        const newGallery = gallery.filter(
                          (item) => item !== Sev_file.path
                        );
                        setGallery(newGallery);
                        setSevFile({});
                        load(res.message);
                      } catch (error) {
                        console.log(error);
                        load();
                      }
                    },
                  }}
                  // name="files"
                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <FilePond
                  files={Eight}
                  onupdatefiles={setEight}
                  allowMultiple={false}
                  allowImageResize={true}
                  allowRemove={true}
                  acceptedFileTypes={["image/*"]}
                  server={{
                    process: async (
                      fieldName,
                      file,
                      metadata,
                      load,
                      error,
                      progress,
                      abort
                    ) => {
                      try {
                        const image = await compressedFile(file);
                        const formData = new FormData();
                        formData.append("imageCover", image);
                        const res = await uploadHomeCoverImage(formData);
                        setEightFile(res.file_data);
                        setGallery([...gallery, res.file_data.path]);

                        load(res.message);
                      } catch (err) {
                        console.log(error);
                        error(err.message);
                      }
                      return {
                        abort: () => {
                          abort();
                        },
                      };
                    },
                    revert: async (uniqueFileId, load) => {
                      const keyObject = { Key: Eight_file.Key };
                      try {
                        const res = await deleteHomeImageFile(keyObject);
                        const newGallery = gallery.filter(
                          (item) => item !== Eight_file.path
                        );
                        setGallery(newGallery);
                        setEightFile({});
                        load(res.message);
                      } catch (error) {
                        console.log(error);
                        load();
                      }
                    },
                  }}
                  // name="files"
                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <FilePond
                  files={Nine}
                  onupdatefiles={setNine}
                  allowMultiple={false}
                  allowImageResize={true}
                  allowRemove={true}
                  acceptedFileTypes={["image/*"]}
                  server={{
                    process: async (
                      fieldName,
                      file,
                      metadata,
                      load,
                      error,
                      progress,
                      abort
                    ) => {
                      try {
                        const image = await compressedFile(file);
                        const formData = new FormData();
                        formData.append("imageCover", image);
                        const res = await uploadHomeCoverImage(formData);
                        setNineFile(res.file_data);
                        setGallery([...gallery, res.file_data.path]);

                        load(res.message);
                      } catch (err) {
                        console.log(error);
                        error(err.message);
                      }
                      return {
                        abort: () => {
                          abort();
                        },
                      };
                    },
                    revert: async (uniqueFileId, load) => {
                      const keyObject = { Key: Nine_file.Key };
                      try {
                        const res = await deleteHomeImageFile(keyObject);
                        const newGallery = gallery.filter(
                          (item) => item !== Nine_file.path
                        );
                        setGallery(newGallery);
                        setNineFile({});
                        load(res.message);
                      } catch (error) {
                        console.log(error);
                        load();
                      }
                    },
                  }}
                  // name="files"
                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <FilePond
                  files={Ten}
                  onupdatefiles={setTen}
                  allowMultiple={false}
                  allowImageResize={true}
                  allowRemove={true}
                  acceptedFileTypes={["image/*"]}
                  server={{
                    process: async (
                      fieldName,
                      file,
                      metadata,
                      load,
                      error,
                      progress,
                      abort
                    ) => {
                      try {
                        const image = await compressedFile(file);
                        const formData = new FormData();
                        formData.append("imageCover", image);
                        const res = await uploadHomeCoverImage(formData);
                        setTenFile(res.file_data);
                        setGallery([...gallery, res.file_data.path]);

                        load(res.message);
                      } catch (err) {
                        console.log(error);
                        error(err.message);
                      }
                      return {
                        abort: () => {
                          abort();
                        },
                      };
                    },
                    revert: async (uniqueFileId, load) => {
                      const keyObject = { Key: Ten_file.Key };
                      try {
                        const res = await deleteHomeImageFile(keyObject);
                        const newGallery = gallery.filter(
                          (item) => item !== Ten_file.path
                        );
                        setGallery(newGallery);
                        setTenFile({});
                        load(res.message);
                      } catch (error) {
                        console.log(error);
                        load();
                      }
                    },
                  }}
                  // name="files"
                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                />
              </Grid>
            </Grid>
            {/* </Grid> */}
          </CardContent>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              p: 2,
            }}
          >
            <Button color="primary" variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </Box>
        </Card>
      </form>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  home: state.homes.home,
});

export default connect(mapStateToProps, {
  uploadHomeCoverImage,
  deleteHomeImageFile,
  editHome,
})(UploadHomeImages);
