import Axios from "axios";
import { APIURL } from "../utils/misc";
import { TRANSCTION_TYPES } from "./types";

const fetch_transactions_success = (payload) => ({
  type: TRANSCTION_TYPES.FETCH_TRANSACTIONS,
  payload,
});

export const fetch_transactions_async = () => async (dispatch) => {
  try {
    const host = localStorage.getItem("HOST_ID");
    const res = await Axios.get(`${APIURL}hosts/${host}/transactions`);
    dispatch(fetch_transactions_success(res.data.transactions));
  } catch (error) {
    console.log("trnasactions", error.message);
  }
};
