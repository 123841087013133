import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  colors,
  Chip,
} from "@material-ui/core";
import { toast } from "react-toastify";
import axios from "axios";
import { APIURL } from "src/utils/misc";
import { getExperienceById } from "src/actions/ExperienceActions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { connect } from "react-redux";

const CustomerListResults = ({ experiences, getExperienceById, ...rest }) => {
  const navigate = useNavigate();
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(0);
  const [copied, setCopied] = useState(false);

  const onDelete = async (expId) => {
    try {
      await axios.delete(`${APIURL}experiences/${expId}`);
      window.location.reload();
    } catch (error) {
      toast.error("Action failed!");
    }
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    console.log(event);
    setPage(newPage);
  };

  const viewExperience = async (experienceId) => {
    await getExperienceById(experienceId, navigate);
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                {/* <TableCell>No.</TableCell> */}
                <TableCell>Actions</TableCell>
                <TableCell>Title</TableCell>
                {/* <TableCell>Price (USD)</TableCell> */}
                <TableCell>Language</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Location</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {experiences.slice(0, limit).map((experience, idx) => (
                <TableRow hover key={experience.id}>
                  {/* <TableCell>{++idx}</TableCell> */}
                  <TableCell>
                    <Chip
                      label="Edit / View"
                      clickable
                      style={{
                        color: colors.orange[600],
                        borderColor: colors.orange[600],
                        marginRight: 5,
                      }}
                      onClick={() => viewExperience(experience._id)}
                      variant="outlined"
                    />
                    {experience.approved ? (
                      <CopyToClipboard
                        text={`https://tubayo.com/experience/${experience._id}`}
                        onCopy={() => {
                          setCopied(!copied);
                          toast.success("Copied!!");
                          setCopied(false);
                        }}
                      >
                        <Chip
                          label="Copy Experience URL"
                          clickable
                          style={{
                            color: "#0d8487",
                            borderColor: "#0d8487",
                            marginRight: 5,
                          }}
                          variant="outlined"
                        />
                      </CopyToClipboard>
                    ) : null}
                    <Chip
                      label="Delete"
                      clickable
                      style={{
                        color: colors.red[600],
                        borderColor: colors.red[600],
                        marginRight: 5,
                      }}
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure you wish to delete this Experience?"
                          )
                        )
                          onDelete(experience._id);
                      }}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>{experience.experienceTitle}</TableCell>
                  {/* <TableCell>{experience.price}</TableCell> */}
                  <TableCell>{experience.language}</TableCell>
                  <TableCell>{experience.experienceCategory}</TableCell>
                  <TableCell>{experience.location}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={experiences.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

CustomerListResults.propTypes = {
  experiences: PropTypes.array.isRequired,
};

export default connect(null, { getExperienceById })(CustomerListResults);
