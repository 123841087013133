import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  Box,
  Container,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { MoneyOff } from "@material-ui/icons";
import { connect } from "react-redux";
import {
  async_fetch_events,
  sendComplementaryTicket,
} from "src/actions/event.action";
import EventListResults from "src/components/events/EventListResults";

function EventsList({ async_fetch_events, events, sendComplementaryTicket }) {
  useEffect(() => {
    const fetchItemsAsync = async () => {
      await async_fetch_events();
    };

    fetchItemsAsync();
  }, [async_fetch_events]);

  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState({});

  const handleClickOpen = () => {
    setOpen(!open);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((formState) => ({ ...formState, [name]: value }));
  };

  return (
    <>
      <Helmet>
        <title>Tubayo Host | Events</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Box sx={{ pt: 3 }}>
            <Box sx={{ mb: 4 }}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={handleClickOpen}
                startIcon={<MoneyOff fontSize="18" />}
              >
                Send Complementary Ticket
              </Button>
            </Box>
            <EventListResults events={events} />
          </Box>
          <Dialog
            open={open}
            onClose={handleClickOpen}
            fullWidth={"true"}
            maxWidth="md"
            PaperProps={{
              component: "form",
              onSubmit: (event) => {
                event.preventDefault();
                const data = {
                  ...formState,
                  numberOfTickets: 1,
                  ticketPrice: 0,
                  ticket: "Complementary",
                };
                sendComplementaryTicket(data);
                handleClickOpen();
              },
            }}
          >
            <DialogTitle>Send Complementary Ticket</DialogTitle>
            <DialogContent>
              <Box
                noValidate
                component="form"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <TextField
                      autoFocus
                      required
                      margin="dense"
                      id="name"
                      name="name"
                      label="Full Name"
                      fullWidth
                      variant="standard"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      autoFocus
                      required
                      margin="dense"
                      name="phone_number"
                      label="Phone Number"
                      fullWidth
                      variant="standard"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      autoFocus
                      required
                      margin="dense"
                      name="email"
                      label="Email Address"
                      type="email"
                      fullWidth
                      variant="standard"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="max-width">Evnet Name</InputLabel>
                      <Select
                        autoFocus
                        onChange={handleChange}
                        label="Event Name"
                        fullWidth
                        variant="standard"
                        inputProps={{
                          name: "eventPaidFor",
                        }}
                      >
                        <MenuItem>Select Event</MenuItem>
                        {events.map((event) => (
                          <MenuItem key={event._id} value={event.eventName}>
                            {event.eventName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClickOpen}>Cancel</Button>
              <Button color="primary" variant="contained" type="submit">
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </Box>
    </>
  );
}

const mapStateToProps = (state) => ({
  events: state.eventReducer.events,
});

export default connect(mapStateToProps, {
  async_fetch_events,
  sendComplementaryTicket,
})(EventsList);
