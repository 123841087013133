import axios from "axios";
import { toast } from "react-toastify";
import { APIURL } from "../utils/misc";
import { HOME_TYPE } from "./types";

export function setHomes(homes) {
  return {
    type: HOME_TYPE.SET_HOME,
    homes,
  };
}

export function fetchHomes() {
  const host = localStorage.getItem("HOST_ID");
  return async (dispatch) => {
    try {
      const res = await axios.get(`${APIURL}hosts/${host}/homes`);
      dispatch(setHomes(res.data.homes));
    } catch (error) {
      dispatch({
        type: HOME_TYPE.HOME_ERROR,
        payload: error.message,
      });
    }
  };
}

export const addHome = (data, navigate) => {
  const host = localStorage.getItem("HOST_ID");
  return async (dispatch) => {
    let res;
    try {
      res = await axios.post(`${APIURL}hosts/${host}/homes`, data);
      if (res.data.status === "fail") {
        toast.warning(
          "Home with title already exist! Please try again with another title."
        );
      } else {
        dispatch({
          type: HOME_TYPE.ADD_HOME,
          payload: res.data,
        });
        navigate("/app/home/addHomeImage", { replace: true });
      }
    } catch (error) {
      if (error.message === "Network Error") {
        dispatch({
          type: HOME_TYPE.HOME_ERROR,
          payload: "Please check your network connection.",
        });
      } else {
        dispatch({
          type: HOME_TYPE.HOME_ERROR,
          payload: "We are working on it, please try again while we implement",
        });
      }
    }
  };
};

export const getHomeById = (data, navigate) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(`${APIURL}homes/${data}`);
      dispatch({
        type: HOME_TYPE.GET_HOME,
        payload: res.data,
      });
      navigate && navigate("/app/home/edit");
    } catch (error) {
      console.log("error", error);
      toast.error(
        "We are working on it, please try again later when we implement it."
      );
    }
  };
};

export const editHome = (data, homeId, navigate) => {
  return async (dispatch) => {
    try {
      let headerType;
      if (!data.imageCover) {
        headerType = {
          "Content-Type": "application/json",
        };
      } else {
        headerType = {
          "Content-Type": "multipart/form-data",
        };
      }
      const res = await axios.patch(`${APIURL}homes/${homeId}`, data, {
        headers: headerType,
      });
      toast.success("Home was added successfully");
      dispatch({
        type: HOME_TYPE.EDIT_HOME,
        payload: res.data,
      });
      navigate("/app/homes", { replace: true });
    } catch (error) {
      toast.error(
        "We are working on it, please try again later when we implement it."
      );
    }
  };
};

export const uploadHomeCoverImage = (data) => async () => {
  try {
    const res = await axios.post(`${APIURL}homes/uploadCover`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return res.data;
  } catch (error) {
    toast.error("File upload failed!");
  }
};

export const deleteHomeImageFile = (data) => async () => {
  try {
    const res = await axios.post(`${APIURL}homes/deleteImage`, data);
    return res.data;
  } catch (error) {
    toast.error("Action  failed!");
  }
};
