import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import reduxThunk from "redux-thunk";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import rootReducer from "./store";

const jwtToken = localStorage.getItem("JWT_TOKEN");
const hostId = localStorage.getItem("HOST_ID");

const store = createStore(
  rootReducer,
  {
    auth: {
      token: jwtToken,
      isAuthenticated: jwtToken ? true : false,
      hostId,
    },
  },
  applyMiddleware(reduxThunk)
);

const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
