import { EVENT_TYPE } from "../../actions/types";

const INITIAL_STATE = {
  events: [],
  event: {},
  eventBookings: [],
};

const eventReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EVENT_TYPE.GET_EVENTS:
      return {
        ...state,
        events: action.payload,
      };
    case EVENT_TYPE.GET_EVENT:
      return {
        ...state,
        event: action.payload,
      };
    case EVENT_TYPE.EVENT_BOOKING_SUCCESS:
      return {
        ...state,
        eventBookings: action.payload,
      };
    default:
      return state;
  }
};

export default eventReducer;
