import { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
} from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import RowingIcon from "@material-ui/icons/Rowing";
import EventIcon from "@material-ui/icons/Event";
import HomeIcon from "@material-ui/icons/Home";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import SyncAltOutlinedIcon from "@material-ui/icons/SyncAltOutlined";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { CalendarTodayOutlined } from "@material-ui/icons";
import InputIcon from "@material-ui/icons/Input";
import MoneyIcon from "@material-ui/icons/Money";
import ReorderIcon from "@material-ui/icons/Reorder";
import NavItem from "./NavItem";

const user = {
  avatar: "/static/images/avatars/avatar_6.png",
  name: "Nicholas Bonny",
};

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
  },
}));

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const classes = useStyles();
  const profile = localStorage.getItem("PHOTO");
  const name = localStorage.getItem("USERNAME");

  const host = useSelector((state) => state.edit_host?.host);

  const items = [
    {
      href: "/app/dashboard",
      icon: DashboardIcon,
      title: "Dashboard",
      options: [],
    },
    {
      href: "/app/customers",
      icon: RowingIcon,
      title: "Experiences",
      options: [
        {
          icon: AddCircleOutlineIcon,
          title: "Add Experiences",
          href:
            host?.firstName && host?.lastName && host?.phoneNumber
              ? "/app/createExperience"
              : "/app/account",
        },
        {
          icon: ReorderIcon,
          title: "Veiw Experiences",
          href: "/app/experiences",
        },
      ],
    },
    {
      href: "/app/products",
      icon: HomeIcon,
      title: "Homes",
      options: [
        {
          icon: AddCircleOutlineIcon,
          title: "Add Homes",
          href:
            host?.firstName && host?.lastName && host?.phoneNumber
              ? "/app/createHome"
              : "/app/account",
        },
        {
          icon: ReorderIcon,
          title: "View Listed Homes",
          href: "/app/homes",
        },
        {
          icon: CalendarTodayOutlined,
          title: "Manage Homes Calendar",
          href: "/app/homes/calendar",
        },
      ],
    },
    {
      href: "/app",
      icon: EventIcon,
      title: "Events",
      options: [
        {
          icon: AddCircleOutlineIcon,
          title: "Add Event",
          href:
            host?.firstName && host?.lastName && host?.phoneNumber
              ? "/app/createEvent"
              : "/app/account",
        },
        {
          icon: ReorderIcon,
          title: "My Events",
          href: "/app/events",
        },
      ],
    },
    {
      href: "/app/account",
      icon: PeopleOutlineIcon,
      title: "Customers",
      options: [
        {
          icon: AddCircleOutlineIcon,
          title: "Add Customers",
          href: "/app/createCustomer",
        },
        {
          icon: ReorderIcon,
          title: "Customers List",
          href: "/app/customers",
        },
      ],
    },
    {
      href: "/app/transactions",
      icon: SyncAltOutlinedIcon,
      title: "Transactions",
      options: [],
    },
    {
      href: "/app/homeBookings",
      icon: MoneyIcon,
      title: "Home Bookings",
      options: [],
    },
    {
      href: "/app/experienceBookings",
      icon: MoneyIcon,
      title: "Experience Bookings",
      options: [],
    },
    {
      href: "/app/eventBookings",
      icon: MoneyIcon,
      title: "Event Bookings",
      options: [],
    },
    {
      href: "/login",
      icon: InputIcon,
      title: "LogOut",
      options: [],
    },
  ];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          p: 2,
        }}
      >
        <Avatar
          component={RouterLink}
          src={profile || user.avatar}
          style={{
            cursor: "pointer",
            width: 64,
            height: 64,
          }}
          to="/app/account"
        />
        <Typography color="textPrimary" variant="h5">
          {name || user.name}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {user.jobTitle}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              options={item.options}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          classes={{ paper: classes.mobileDrawer }}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          classes={{ paper: classes.desktopDrawer }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default DashboardSidebar;
