import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Container,
  TextField,
  Card,
  CardContent,
  CardHeader,
} from "@material-ui/core";
import { connect } from "react-redux";
import { COUNTRY_PHONE_CODES } from "src/utils/country_codes";
import { add_customer_async } from "src/actions/customer.actions";

const CreateCustomer = ({ country_code, add_customer_async }) => {
  const navigate = useNavigate();

  let code = !country_code ? "UG" : country_code;
  const zipCode = COUNTRY_PHONE_CODES[code];

  return (
    <>
      <Helmet>
        <title>Tubayo Host | Customers</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          marginTop: 10,
        }}
      >
        <Container>
          <Card>
            <CardHeader title="Customers" subheader="Add your customer here" />
            <CardContent>
              <Formik
                initialValues={{
                  email: "",
                  firstName: "",
                  lastName: "",
                  phoneNumber: `+${zipCode}`,
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email("Must be a valid email")
                    .max(255)
                    .required("Email is required"),
                  firstName: Yup.string()
                    .max(255)
                    .required("First name is required"),
                  lastName: Yup.string()
                    .max(255)
                    .required("Last name is required"),
                  phoneNumber: Yup.string()
                    .max(255)
                    .required("phoneNumber is required"),
                })}
                onSubmit={async (values) => {
                  const obj = {
                    ...values,
                    phoneNumber: values.phoneNumber.replace(/\D/g, ""),
                  };
                  await add_customer_async(obj, navigate);
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      error={Boolean(touched.firstName && errors.firstName)}
                      fullWidth
                      helperText={touched.firstName && errors.firstName}
                      label="First name"
                      margin="normal"
                      name="firstName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      defaultValue={values.firstName}
                      variant="outlined"
                    />
                    <TextField
                      error={Boolean(touched.lastName && errors.lastName)}
                      fullWidth
                      helperText={touched.lastName && errors.lastName}
                      label="Last name"
                      margin="normal"
                      name="lastName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      defaultValue={values.lastName}
                      variant="outlined"
                    />
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Email Address"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      defaultValue={values.email}
                      variant="outlined"
                    />
                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      label="Phone Number"
                      margin="normal"
                      name="phoneNumber"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      defaultValue={values.phoneNumber}
                      variant="outlined"
                    />
                    <Box sx={{ py: 2 }}>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        submit
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  country_code: state.location.country_code,
});

export default connect(mapStateToProps, { add_customer_async })(CreateCustomer);
