import React from "react";
import { Helmet } from "react-helmet";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Box,
  Container,
  makeStyles,
  Chip,
  colors,
} from "@material-ui/core";
// import { makeStyles } from "@material-ui/styles";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";
import { connect } from "react-redux";
import {
  homeReservationAsync,
  updateReservationAsync,
} from "src/actions/EditHost";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    marginTop: 20,
    paddingBottom: 10,
    paddingTop: 10,
    paddingLeft: 10,
  },
  cursorPointer: {
    cursor: "pointer",
  },
  viewBtns: {
    textAlign: "center",
    color: "#fff",
    fontWeight: "500",
  },
}));

function HomeReservations({
  homeReservationAsync,
  reservations,
  updateReservationAsync,
}) {
  const classes = useStyles();

  React.useEffect(() => {
    homeReservationAsync();
    // eslint-disable-next-line
  }, []);

  return (
    <Container className={classes.root} title="Users list">
      <Helmet>
        <title>Tubayo Host | Home Reservations</title>
      </Helmet>
      <Box>
        <Card>
          <PerfectScrollbar>
            <Box minWidth={600}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell />
                    <TableCell>Home</TableCell>
                    <TableCell>Guest Names</TableCell>
                    {/* <TableCell>Contact</TableCell> */}
                    <TableCell>Checkin Date</TableCell>
                    <TableCell>Nights</TableCell>
                    <TableCell>No. of Guests</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reservations &&
                    reservations.slice(0, 10).map((reservation, index) => (
                      <TableRow
                        hover
                        key={index}
                        className={classes.cursorPointer}
                      >
                        <TableCell>
                          {moment(reservation.createdAt).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell>
                          {reservation.status === "pending" ? (
                            <>
                              <Chip
                                label="Accept"
                                clickable
                                style={{
                                  color: colors.orange[600],
                                  borderColor: colors.orange[600],
                                  marginRight: 5,
                                }}
                                onClick={() =>
                                  updateReservationAsync(
                                    {
                                      ...reservation,
                                      status: "approved",
                                    },
                                    reservation._id
                                  )
                                }
                                variant="outlined"
                              />
                              <Chip
                                label="Reject"
                                clickable
                                onClick={() =>
                                  updateReservationAsync(
                                    {
                                      ...reservation,
                                      status: "rejected",
                                    },
                                    reservation._id
                                  )
                                }
                                style={{
                                  color: colors.red[600],
                                  borderColor: colors.red[600],
                                  marginRight: 5,
                                }}
                                variant="outlined"
                              />
                            </>
                          ) : (
                            <Chip
                              label={reservation.status}
                              clickable={false}
                              style={{
                                backgroundColor:
                                  reservation.status === "approved"
                                    ? "#0d8487"
                                    : colors.red[600],
                                color: "#fff",

                                marginRight: 5,
                              }}
                              // variant="outlined"
                            />
                          )}
                        </TableCell>
                        <TableCell>{`${reservation.home.homeTitle}`}</TableCell>
                        <TableCell>{`${reservation.firstName} ${reservation.lastName}`}</TableCell>
                        {/* <TableCell>{reservation.phoneNumber}</TableCell> */}
                        <TableCell>
                          {new Date(reservation.checkInDate).toDateString()}
                        </TableCell>
                        <TableCell>{reservation.nightsToStay}</TableCell>
                        <TableCell>{reservation.numberOfGuests}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={reservations?.length}
            page={1}
            rowsPerPage={10}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Card>
      </Box>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  reservations: state.edit_host.reservations,
});

export default connect(mapStateToProps, {
  homeReservationAsync,
  updateReservationAsync,
})(HomeReservations);
