import { HOME_TYPE } from "../../actions/types";

const initialState = {
  homes: [],
  home: null,
  errorMessage: "",
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case HOME_TYPE.SET_HOME:
      return {
        ...state,
        homes: [...action.homes],
      };
    case HOME_TYPE.ADD_HOME:
      return {
        ...state,
        status: action.payload.status,
        home: action.payload.home,
      };
    case HOME_TYPE.GET_HOME:
      return {
        ...state,
        home: action.payload,
      };
    case HOME_TYPE.EDIT_HOME:
      return {
        ...state,
        successMessage: action.payload.message,
      };
    case HOME_TYPE.HOMR_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default homeReducer;
